import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import Types from "../../classes/types";
import * as platform from '../../utils/platform';

import TrackedComponent from '../../common/components/TrackedComponent';

/**
 * Wrapper for Scrollbars component
 */
class CustomScrollBars extends TrackedComponent {
    constructor(props) {
        super(props);
        this.componentName = this.props.name;

        this.scrollRef = React.createRef();
        this.state = {
            scrollValues: {}
        };
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.updateDimensions);
    }

    applyStateCallback(prevState) {
        const { scrollValues } = this.state;
        let scrollBarsRef = (this.props.scrollBarsRef && this.props.scrollBarsRef.current) ? this.props.scrollBarsRef.current : this.scrollRef.current;
        if (scrollBarsRef && scrollValues) {
            const currentValues = scrollBarsRef.getValues();
            const scrollTop = currentValues.scrollHeight * (scrollValues.scrollTop / scrollValues.scrollHeight);
            scrollBarsRef.scrollTop(scrollTop);
        }
    }

    onScrollFrame = (positionValues) => {
        this.setState({ scrollValues: positionValues });
    }

    scrollToTop() {
        this.scrollRef.current.scrollToTop();
    }

    updateDimensions = () => {
        const { useCssDefaultScrollHiding } = this.props;

        if (useCssDefaultScrollHiding) {
            this.setState({ scrollWidth: 0, scrollHeight: 0, cssDefaultScrollHidingClassName: 'CustomScrollBars__view__hideScroll' });
        } else {
            let div = document.createElement('div');

            div.style.overflowY = 'scroll';
            div.style.overflowX = 'scroll';
            div.style.width = '50px';
            div.style.height = '50px';

            div.style.visibility = 'hidden';

            document.body.appendChild(div);
            let scrollWidth = div.offsetWidth - div.clientWidth + 1;
            let scrollHeight = div.offsetHeight - div.clientHeight + 1;

            document.body.removeChild(div);

            this.setState({ scrollWidth, scrollHeight });
        }
    };

    render() {
        let scrollBarsRef = (this.props.scrollBarsRef) ? this.props.scrollBarsRef : this.scrollRef;
        const { scrollWidth, scrollHeight, cssDefaultScrollHidingClassName } = this.state;
        const { hideScrollbar } = this.props;

        return (
            <Scrollbars
                {...this.props}
                ref={scrollBarsRef}
                autoHide={this.props.autoHide}
                onScrollFrame={this.onScrollFrame}
                renderView={props =>
                    <div
                        {...props}
                        className={classNames('CustomScrollBars__view', Types.SCROLL_PARENT, this.props.className, cssDefaultScrollHidingClassName)}
                        style={{
                            position: 'absolute', top: '0px', left: '0px', right: '0px', bottom: '0px',
                            overflowY: 'scroll', overflowX: 'scroll', marginRight: -scrollWidth + 'px', marginBottom: -scrollHeight + 'px'
                        }}
                    />
                }
                renderTrackHorizontal={props => <div {...props} className={classNames("CustomScrollBars__horizontal__background", {"CustomScrollBars__hideScrollbar": hideScrollbar})} />}
                renderThumbHorizontal={props => <div {...props} className="CustomScrollBars__horizontal__thumb" />}
                renderTrackVertical={props => <div {...props} className={classNames("CustomScrollBars__vertical__background", {"CustomScrollBars__hideScrollbar": hideScrollbar})} />}
                renderThumbVertical={props => <div {...props} className="CustomScrollBars__vertical__thumb" />}
            >
                {this.props.children}
            </Scrollbars>
        );
    }
}

CustomScrollBars.defaultProps = {
    autoHide: platform.isVersion6DesktopTarget,
}

CustomScrollBars.propTypes = {
    autoHide: PropTypes.bool,
};

export default CustomScrollBars;