import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { fetchSvg } from './fetcher';
import { extractFileName } from './utils';

const SVG_NAMESPACE = 'http://www.w3.org/2000/svg';

const INJECTION_DELAY = 20;

class Injector {
    fragment = null;

    symbolsMountingPoint;

    requests = {};
    symbols = {};

    parseSvgString(svg) {
        return new DOMParser().parseFromString(svg, 'image/svg+xml')
            .children[0];
    }

    svgToSymbol = ({ id, svgString, className, propsViewBox }) => {
        const svgDocument = this.parseSvgString(svgString);
        const viewBox = svgDocument.getAttribute('viewBox');
        const svgClassName = svgDocument.getAttribute('class');
        const svgFileName = id;
        const symbol = document.createElementNS(SVG_NAMESPACE, 'symbol');
        let data = {};

        if (viewBox) {
            const width = +viewBox.split(' ')[2];
            const height = +viewBox.split(' ')[3];
            symbol.setAttributeNS(null, 'viewBox', propsViewBox || viewBox);
            data = {
                width,
                height,
            };
        }

        data.id = id;
        data.className = svgFileName !== svgClassName ? `${svgFileName} ${svgClassName}` : svgFileName;
        this.symbols = { ...this.symbols, [id]: data };
        const combinedClassName = classNames(data.className, className);

        symbol.setAttributeNS(null, 'id', id);
        symbol.setAttributeNS(null, 'class', combinedClassName);
        symbol.innerHTML = svgDocument.innerHTML;

        return symbol;
    }

    getData = (id) => this.symbols[id] || {};

    accumulateSvg = ({ url, svgString, className, propsViewBox }) => {
        const id = this.getId(url);
        const symbol = this.svgToSymbol({ id, svgString, className, propsViewBox });

        this.fragment = this.fragment || document.createDocumentFragment();
        this.fragment.append(symbol);
        return symbol;
    };

    flushSvg = () => {
        const sprite = this.getSymbolMountPoint();

        if (this.fragment) {
            sprite.appendChild(this.fragment);
            this.fragment = null;
        }
    };

    deboucedflushSvg = debounce(this.flushSvg, INJECTION_DELAY);

    getSymbolMountPoint() {
        if (!this.symbolsMountingPoint) {
            const sprite = document.createElementNS(SVG_NAMESPACE, 'svg');
            this.symbolsMountingPoint = sprite;

            sprite.ariaHidden = 'true';
            sprite.style.width = '0';
            sprite.style.height = '0';
            sprite.style.overflow = 'hidden';

            document.body.appendChild(sprite);
        }

        return this.symbolsMountingPoint;
    }

    getId(url) {
        return extractFileName(url);
    }

    async load(url, { flushImmediate, timeout, retryCount, className, propsViewBox } = {}) {
        const id = this.getId(url);

        if (this.requests[id] === "finished") return;

        if (this.requests[id]) return await this.requests[id];

        this.requests[id] = fetchSvg(url, { timeout, retryCount });

        return this.requests[id]
            .then((svgString) => {
                this.accumulateSvg({ url, svgString, className, propsViewBox });
                flushImmediate ? this.flushSvg() : this.deboucedflushSvg();
                this.requests[id] = "finished";
            })
            .catch((error) => {
                delete this.requests[id];
                throw error;
            });
    }
}

export const injector = new Injector();
