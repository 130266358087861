import { List, Record } from 'immutable';
import * as platformNotificationsActions from '../../actions/user/platformNotifications';

const initialState = new Record({
    list: new List(),
    status: platformNotificationsActions.NOT_FETCHED,
})();

export default function platformNotifications(state = initialState, action) {
    switch (action.type) {
        case platformNotificationsActions.FETCHED: {
            return state.merge({
                list: action.payload,
                status: platformNotificationsActions.FETCHED,
            });
        }
        default:
            return state;
    }
}
