import { wdApi, dispatchPost } from '../../services';
import { base64 } from '../../utils';

export const DEPOSIT_BEGIN_REQUEST = 'DEPOSIT_BEGIN_REQUEST';
export const DEPOSIT_END_REQUEST = 'DEPOSIT_END_REQUEST';
export const DEPOSIT_CHANGE_ACCOUNT = 'DEPOSIT_CHANGE_ACCOUNT';
export const DEPOSIT_CHANGE_PAYMENT_METHOD = 'DEPOSIT_CHANGE_PAYMENT_METHOD';
export const DEPOSIT_CHANGE_PAYMENT_METHOD_AND_ACCOUNT = 'DEPOSIT_CHANGE_PAYMENT_METHOD_AND_ACCOUNT';
export const DEPOSIT_CHANGE_DEMO_ACCOUNT_NUMBER = 'DEPOSIT_CHANGE_DEMO_ACCOUNT_NUMBER';

export function beginRequest() {
    return {
        type: DEPOSIT_BEGIN_REQUEST,
    }
}

export function endRequest(result) {
    return {
        type: DEPOSIT_END_REQUEST,
        payload: result,
    }
}

export function changeAccount(account) {
    return {
        type: DEPOSIT_CHANGE_ACCOUNT,
        payload: account,
    }
}

export function changePaymentMethod(paymentMehodId) {
    return {
        type: DEPOSIT_CHANGE_PAYMENT_METHOD,
        payload: paymentMehodId,
    }
}

export function changePaymentMethodAndAccount(data) {
    return {
        type: DEPOSIT_CHANGE_PAYMENT_METHOD_AND_ACCOUNT,
        payload: data,
    }
}

/**
 * Change demo account number
 * @param {string} accountNumber 
 */
export function changeDemoAccountNumber(accountNumber) {
    return {
        type: DEPOSIT_CHANGE_DEMO_ACCOUNT_NUMBER,
        payload: accountNumber,
    }
}

export function doDeposit({
    userId,
    accountId,
    bailAccountId,
    paymentMethodNumber,
    paymentSystemNumber,
    sum,
    currency,
    exchangeAmount,
    exchangeCurrency,
    IBAN,
    SWIFT,
    expYear,
    expMonth,
    CSC,
    holderFirstName,
    holderLastName,
    holderEmail,
    holderPhone,
    holderCountry,
    holderState,
    holderCity,
    holderAddress,
    holderZip,
    bankAddress,
    bankAccount,
    bankPhone,
    bankName,
    mobile,
    }) {
    return (dispatch, getState) => {
        dispatch(beginRequest());

        return dispatchPost('DepositAccount', {
            UserId: +userId,
            AccountId: +accountId,
            Sum: sum,
            Currency: currency,
            ExchangeSum: exchangeAmount,
            ExchangeCurrency: exchangeCurrency,
            PaymentMethod: paymentMethodNumber,
            PaymentSystem: paymentSystemNumber,
            IBAN: IBAN,
            ExpYear: expYear,
            ExpMonth: expMonth,
            CSC: CSC,
            HolderFirstName: holderFirstName,
            HolderLastName: holderLastName,
            HolderEmail: holderEmail,
            HolderPhone: holderPhone,
            HolderCountry: holderCountry,
            HolderState: holderState,
            HolderCity: holderCity,
            HolderAddress: holderAddress,
            HolderZip: holderZip,
            BailAccountId: bailAccountId,
            SWIFT: SWIFT,
            BankAddress: bankAddress,
            BankAccount: bankAccount,
            BankPhone: bankPhone,
            BankName: bankName,
            Mobile: mobile,
        }).then((response) => {
            dispatch(endRequest(true));
            return response;
        });
    }
}

export function doTransferMoney({ fromAccountNumber, toAccountNumber, amount }) {
    return (dispatch, getState) => {
        return dispatchPost('TransferMoney', {
            FromAccountId: fromAccountNumber,
            ToAccountId: toAccountNumber,
            Sum: amount,
        }).then(response => {
            if (!response || !response.data || (response.data && response.data.Error)) {
                return false;
            }

            return !!response.data.Result;
        });
    }
}

export function doCheckPaymentUser({ email, systemCode }) {
    return (dispatch, getState) => {
        dispatch(beginRequest());

        return wdApi.post('CheckPaymentUser', {
            Email: email,
            SystemCode: systemCode,
        }).then(response => {
            dispatch(endRequest(true));
            return response;
        });
    }
}

export function doRegisterPaymentUser({
    firstName,
    lastName,
    address,
    birthDate,
    country,
    systemCode
}) {
    return (dispatch, getState) => {
        dispatch(beginRequest());

        return wdApi.post('RegisterPaymentUser', {
            FirstName: firstName,
            LastName: lastName,
            Address: address,
            BirthDate: birthDate,
            CountryCode: country.toUpperCase(),
            SystemCode: systemCode,
        }).then(response => {
            dispatch(endRequest(true));
            return response;
        });
    }
}

export function doRegisterPaymentPhone({ phone, countryCode, systemCode }) {
    return (dispatch, getState) => {
        dispatch(beginRequest());

        return wdApi.post('RegisterPaymentPhone', {
            Phone: phone,
            CountryCode: countryCode.toUpperCase(),
            SystemCode: systemCode,
        }).then(response => {
            dispatch(endRequest(true));
            return response;
        });
    }
}

export function doApprovePaymentPhone({ phone, countryCode, code, systemCode}) {
    return (dispatch, getState) => {
        dispatch(beginRequest());

        return wdApi.post('ApprovePaymentPhone', {
            Phone: phone,
            CountryCode: countryCode.toUpperCase(),
            Code: code,
            SystemCode: systemCode,
        }).then(response => {
            dispatch(endRequest(true));
            return response;
        });
    }
}

export function doUploadPaymentFile({ fileName, file, systemCode }) {
    return (dispatch, getState) => {
        dispatch(beginRequest());

        return wdApi.post('UploadPaymentFile', {
            Name: fileName,
            Data: base64.base64ArrayBuffer(file),
            SystemCode: systemCode,
        }).then(response => {
            dispatch(endRequest(true));
            return response;
        });
    }
}