/**
 * Created by ebondarev
 */
import { Record, Map, List } from 'immutable';

import Types from '../../classes/types';
import { store } from '../../store';
import { sort } from '../utils';
import { getAccountsByType } from './accounts';
import * as actionTypes from '../../actions/trade/positions';
import * as actionTickersTypes from '../../actions/trade/tickers';
import * as chartTypes from '../../actions/trade/chart';
import * as userTypes from '../../actions/user/user';
import * as accountsTypes from '../../actions/trade/accounts';
import * as trackingTypes from '../../actions/tracking/tracking';
import * as transportTradeTypes from '../../actions/transports/trade';
import { PositionModel } from '../../models/position';
import { PositionsGroupModel } from '../../models/positions-group';
import { calcAccountPnl } from '../../utils/trade';
import { customerFilter } from '../../utils/filters';


class SummaryPositions {
    swaps = 0;
    _unitVolume = 0;
    unitVolume = 0;
    _unitInLots = 0;
    unitInLots = 0;
    commission = 0;
    accountPnl = 0;
    pl = 0;


    updateFromPosition(position) {
        this.swaps += position.Swaps;
        this._unitVolume += position.UnitVolume * 100000000;
        this._unitInLots += position.unitInLots * 100000000;
        this.commission += position.Comission;
        this.accountPnl += position.accountPnl;
        this.pl += position.profit;
        this.unitVolume = this._unitVolume / 100000000;
        this.unitInLots = this._unitInLots / 100000000;
    }
}

class PositionsStore extends Record({
    list: new Map(),
    listToShow: new Map(),
    positionsGroups: new List(),
    isOpenList: new List(),              // list ids to show details in the table
    symbolIdToOrderMap: new Map(),
    status: actionTypes.NOT_FETCHED,
    sortAttribute: 'UTCDateTime',
    sortDirection: Types.SORT_DESC,
    demoAccount: null,
    lastUpdate: Date.now(),
    summary: new SummaryPositions(),
    pnlCurrency: null,
    pnlCurrencies: [],
    precision: 2,
    activePositionOnChart: "",
    activePositionNumber: 0,
    positionsSearch: ''
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }
}

function convertDataToOrdersMap(data, isAdded = false) {
    const tickersStore = store.getState().trade.tickers;
    const accountsList = store.getState().trade.accounts.list;

    return new Map(data)
        .map((orderData) => {
            const position = new PositionModel(orderData);
            position.isAdded = isAdded;
            const ticker = tickersStore.list.get(position.SymbolId);
            const account = accountsList.get(position.AccountNumber);

            position.unitInLots = position.Amount;

            if (ticker) {
                position.updateAttributesFromQuoteOrTicker(ticker);
                position.precision = ticker.PricePrecision;
            }

            if (account) {
                position.accountName = account.Name;
                position.accountCurrency = account.BaseCurrency;
            }

            if (ticker && account) {
                const crossRate = tickersStore.getCrossRate(position.Exp2, account.BaseCurrency);
                position.accountPnl = calcAccountPnl(crossRate, position);
            }

            return position;
        })
        .sortBy((order) => -order.UTCDateTime);
}

function symbolIdToOrderMap(list) {
    return list.map((order) => {
        return {
            OrderNumber: order.OrderNumber,
            SymbolId: order.SymbolId,
        }
    }).groupBy((data) => {
        return data.SymbolId
    })
}

function updateFromQuote(state, quote) {
    if (!state.symbolIdToOrderMap.has(quote.SymbolId)) {
        return false;
    }

    const tickersStore = store.getState().trade.tickers;
    const accountsList = store.getState().trade.accounts.list;

    state.symbolIdToOrderMap.get(quote.SymbolId).forEach(function (data) {
        state.list.update(data.OrderNumber, (position) => {
            position.updateAttributesFromQuoteOrTicker(quote);
            const ticker = tickersStore.list.get(position.SymbolId);
            const account = accountsList.get(position.AccountNumber);

            if (ticker && account) {
                const crossRate = tickersStore.getCrossRate(position.Exp2, account.BaseCurrency);
                position.bidDiff = ticker.bidDiff;
                position.accountPnl = calcAccountPnl(crossRate, position);
            }

            return position;
        });
    });

    return true;
}

function listToPositionsGroups(list) {
    let data = {};

    list.forEach(position => {
        if (data[position.SymbolId]) {
            data[position.SymbolId].orderNumbers.push(position.OrderNumber);
            data[position.SymbolId].amount += position.Amount;
            data[position.SymbolId].unitVolume += position.UnitVolume;

            if (data[position.SymbolId].lastOpenTime < position.dateTime) {
                data[position.SymbolId].lastOpenTime = position.dateTime;
            }

            if (data[position.SymbolId].buySell !== Types.SIDE_BUYSELL
                && data[position.SymbolId].buySell !== position.BuySell) {
                data[position.SymbolId].buySell = Types.SIDE_BUYSELL;
            }
        } else {
            data[position.SymbolId] = new PositionsGroupModel({
                symbolId: position.SymbolId,
                amount: position.Amount,
                unitVolume: position.UnitVolume,
                orderNumbers: [position.OrderNumber],
                lastOpenTime: position.dateTime,
                buySell: position.BuySell,
            });
        }
    });

    return sort(List(Object.values(data)), 'lastOpenTime', Types.SORT_DESC);
}

function listToPnlCurrencies(list) {
    let currencies = {};

    list.forEach(position => {
        const account = store.getState().trade.accounts.list.get(position.AccountNumber);
        currencies[account.BaseCurrency] = true;
    });

    return Object.keys(currencies);
}

function getPnlCurrency(currencies, currency) {
    return currencies.indexOf(currency) !== -1 ? currency : currencies[0];
}

export function filterListByAccount(list, accountNumber) {
    if (!accountNumber && list.size) {
        return list.clear();
    }

    return list.filter((position) => {
        return position.AccountNumber === accountNumber
            && position.OrderType !== Types.ORDER_TYPE_LIMIT_TP
            && position.OrderType !== Types.ORDER_TYPE_STOP_SL;
    });
}

export function filterListToShow(list, isDemo) {
    const accounts = getAccountsByType(isDemo);

    return list.filter((position) => {
        return accounts.has(position.AccountNumber)
            && position.OrderType !== Types.ORDER_TYPE_LIMIT_TP
            && position.OrderType !== Types.ORDER_TYPE_STOP_SL;
    });
}

function getSummary(listToShow) {
    const summary = new SummaryPositions();

    listToShow.forEach((position) => {
        summary.updateFromPosition(position);
    });

    return summary;
}

const initialState = new PositionsStore();

export default function positions(state = initialState, action) {

    switch (action.type) {

        case actionTickersTypes.UPDATE_QUOTE:
            const updatedAfterQuote = updateFromQuote(state, action.payload);

            return !updatedAfterQuote ? state : state.merge({
                lastUpdate: Date.now(),
                summary: getSummary(state.listToShow)
            });

        case actionTickersTypes.UPDATE_QUOTES:
        case transportTradeTypes.UPDATE_DATA:
            const updatedData = action.type === transportTradeTypes.UPDATE_DATA
                ? action.payload.quotes : action.payload;

            if (!updatedData) {
                return state;
            }

            let updatedAfterQuotes = false;
            for (let symbolId in updatedData) {
                updatedAfterQuotes = updatedAfterQuotes || updateFromQuote(state, updatedData[symbolId]);
            }

            return !updatedAfterQuotes ? state : state.merge({
                lastUpdate: Date.now(),
                summary: getSummary(state.listToShow)
            });

        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING
            });

        case actionTypes.FETCHED:
            const list = sort(convertDataToOrdersMap(action.payload), state.sortAttribute, state.sortDirection);
            const listFetchedToShow = filterListToShow(list, state.demoAccount);
            const fetchedPnlCurrencies = listToPnlCurrencies(listFetchedToShow);

            return state.merge({
                list,
                listToShow: listFetchedToShow,
                positionsGroups: listToPositionsGroups(listFetchedToShow),
                pnlCurrency: getPnlCurrency(fetchedPnlCurrencies, state.pnlCurrency),
                pnlCurrencies: fetchedPnlCurrencies,
                symbolIdToOrderMap: symbolIdToOrderMap(list),
                status: actionTypes.FETCHED,
                lastUpdate: Date.now(),
                summary: getSummary(listFetchedToShow)
            });

        case actionTypes.POSITIONS_FILTERS_UPDATED: {
            const appStore = store.getState();
            const positionsFiltersState = appStore.trade.filters.positionsFilters;

            const listToShow = filterListToShow(state.list, state.demoAccount)
                .filter(customerFilter(positionsFiltersState));

            return state.merge({
                listToShow: listToShow,
                positionsGroups: listToPositionsGroups(listToShow),
            });
        }

        case actionTypes.POSITIONS_SEARCH: {
            return state.merge({
                positionsSearch: action.payload,
            });
        }

        case trackingTypes.GET_STORE:
            return (action.data.positions = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE:
            if (action.payload && action.payload.positions) {
                const positionsData = action.payload.positions;
                const positionsMap = new Map(positionsData.list).map((positionData) => { return new PositionModel(positionData); });
                const dataListToShow = new Map(positionsData.listToShow);
                const positionsMapToShow = sort(positionsMap.filter(position => dataListToShow.has(position.OrderNumber)), state.sortAttribute, state.sortDirection);

                let openedList = new List();
                positionsData.isOpenList.forEach(orderId => {
                    if (positionsMap.has(orderId)) openedList = openedList.push(orderId);
                });

                return state.merge({
                    list: positionsMap,
                    listToShow: positionsMapToShow,
                    positionsGroups: listToPositionsGroups(positionsMapToShow),
                    pnlCurrency: positionsData.pnlCurrency,
                    pnlCurrencies: positionsData.pnlCurrencies,
                    symbolIdToOrderMap: symbolIdToOrderMap(positionsMap),
                    demoAccount: positionsData.demoAccount,
                    isOpenList: openedList,
                    lastUpdate: Date.now(),
                    summary: getSummary(positionsMapToShow)
                });
            }
            return state;

        case actionTypes.ADD:
            const appStoreState = store.getState();
            const listAdd = sort(state.list.merge(convertDataToOrdersMap(action.payload, true)), state.sortAttribute, state.sortDirection);

            const listAddToShow = filterListToShow(listAdd, state.demoAccount)
                .filter(customerFilter(appStoreState.trade.filters.positionsFilters));

            const addPnlCurrencies = listToPnlCurrencies(listAddToShow);

            return state.merge({
                list: listAdd,
                listToShow: listAddToShow,
                positionsGroups: listToPositionsGroups(listAddToShow),
                pnlCurrency: getPnlCurrency(addPnlCurrencies, state.pnlCurrency),
                pnlCurrencies: addPnlCurrencies,
                symbolIdToOrderMap: actionTypes.UPDATE ?
                    symbolIdToOrderMap(listAdd) : state.symbolIdToOrderMap,
                lastUpdate: Date.now(),
                summary: getSummary(listAddToShow)
            });

        case actionTypes.UPDATE:
            const dataToUpdate = {};
            Object.keys(action.payload).forEach((key) => {
                if (state.list.has(key)) {
                    dataToUpdate[key] = action.payload[key];
                }
            });

            const listUpdate = state.list.merge(convertDataToOrdersMap(dataToUpdate));
            const listUpdateToShow = filterListToShow(listUpdate, state.demoAccount);

            return state.merge({
                list: listUpdate,
                listToShow: listUpdateToShow,
                positionsGroups: listToPositionsGroups(listUpdateToShow),
                symbolIdToOrderMap: actionTypes.UPDATE ?
                    symbolIdToOrderMap(listUpdate) : state.symbolIdToOrderMap,
                lastUpdate: Date.now(),
                summary: getSummary(listUpdateToShow),
            });

        case actionTypes.ACTIVE_POSITION_ON_CHART: {
            return state.merge({
                activePositionOnChart: action.payload,
            });
        }

        case actionTypes.UNSET_ACTIVE_POSITION:
        case chartTypes.CHANGE_ACTIVE_TAB: {
            return state.merge({
                activePositionOnChart: '',
                activePositionNumber: 0,
            });
        }

        case actionTypes.REMOVE:
            let listAfterRemove = state.list;
            let activePositionOnChart = state.activePositionOnChart;

            Object.keys(action.payload).forEach(id => {
                if (activePositionOnChart === id) {
                    activePositionOnChart = '';
                }

                listAfterRemove = listAfterRemove.remove(id);
            });

            const appStore = store.getState();
            const positionsFiltersState = appStore.trade.filters.positionsFilters;
            const listAfterRemoveToShow = sort(filterListToShow(listAfterRemove, state.demoAccount)
                .filter(customerFilter(positionsFiltersState)), state.sortAttribute, state.sortDirection);

            // let listAfterRemoveToShow = filterListToShow(listAfterRemove, state.demoAccount);
            const removePnlCurrencies = listToPnlCurrencies(listAfterRemoveToShow);

            return state.merge({
                list: listAfterRemove,
                listToShow: listAfterRemoveToShow,
                positionsGroups: listToPositionsGroups(listAfterRemoveToShow),
                pnlCurrency: getPnlCurrency(removePnlCurrencies, state.pnlCurrency),
                pnlCurrencies: removePnlCurrencies,
                symbolIdToOrderMap: symbolIdToOrderMap(listAfterRemove),
                lastUpdate: Date.now(),
                activePositionOnChart,
                summary: getSummary(listAfterRemoveToShow)
            });

        case actionTypes.CHANGE_PNL_CURRENCY: {
            return state.merge({
                pnlCurrency: action.payload,
            })
        }

        case actionTypes.CHANGE_SORT_ATTRIBUTE:
            if (state.sortAttribute === action.payload) {
                return state;
            }

            const listSortedAttribute = sort(state.list, action.payload, state.sortDirection);
            return state.merge({
                list: listSortedAttribute,
                listToShow: filterListToShow(listSortedAttribute, state.demoAccount),
                sortAttribute: action.payload,
            });

        case actionTypes.CHANGE_SORT_DIRECTION:
            if (state.sortDirection === action.payload) {
                return state;
            }

            const listSortedDirection = sort(state.list, state.sortAttribute, action.payload);

            return state.merge({
                list: listSortedDirection,
                listToShow: filterListToShow(listSortedDirection, state.demoAccount),
                sortDirection: action.payload,
            });

        case accountsTypes.CHANGED_CURRENT_ACCOUNT: {
            const isDemo = action.payload ? action.payload.isDemo : null;
            const appStore = store.getState();
            const positionsFiltersState = appStore.trade.filters.positionsFilters;
            const listToShowAfterChangeAccountName = filterListToShow(state.list, isDemo)
                .filter(customerFilter(positionsFiltersState));
            const accountPnlCurrencies = listToPnlCurrencies(listToShowAfterChangeAccountName);

            return state.merge({
                demoAccount: isDemo,
                listToShow: listToShowAfterChangeAccountName,
                positionsGroups: listToPositionsGroups(listToShowAfterChangeAccountName),
                pnlCurrency: getPnlCurrency(accountPnlCurrencies, state.pnlCurrency),
                pnlCurrencies: accountPnlCurrencies,
                lastUpdate: Date.now(),
                summary: getSummary(listToShowAfterChangeAccountName)
            });
        }

        case userTypes.LOG_OUT:
            return initialState.merge({
                list: new Map(),
                listToShow: new Map(),
                positionsGroups: new List(),
                symbolIdToOrderMap: new Map()
            });

        case actionTypes.TOGGLE_DETAILS_OF_POSITION:
            const orderId = action.payload;
            if (state.isOpenList.includes(orderId)) {
                return state.merge({
                    isOpenList: state.isOpenList.filter(id => id !== orderId)
                });
            } else {
                return state.merge({
                    isOpenList: state.isOpenList.push(orderId)
                });
            }

        default:
            return state;
    }
}
