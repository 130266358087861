/**
 * Created by ebondarev
 */
import { transport, lang } from '../../services';
import Transport from '../../classes/transport';
import Types from '../../classes/types'; 
import { store } from '../../store';
import { getFieldMap } from '../../utils/object';
import { getPositionsGroupSuccessMessageText } from '../../utils/trade';
import * as tickersActions from '../trade/tickers';
import * as accountActions from '../trade/accounts';
import * as authActions from '../user/auth';
import * as appActions from '../app';
import * as ordersActions from '../trade/orders';
import * as positionsActions from '../trade/positions';
import * as tradeSessionsActions from '../trade/trade-sessions';
import * as transportActions from './main';
import * as tickersGroupsActions from '../trade/tickers-groups';
import * as commissionsActions from '../trade/commissions';
import * as commissionPaymentsActions from '../trade/commission-payments';
import * as swapsActions from '../trade/swaps';
import * as marginModelsActions from '../trade/margin-models';
import * as interchangeActions from '../trade/interchanges';
import * as trackingActions from '../tracking/tracking';
import * as chartAction from '../trade/chart';
import * as platformNotifications from '../user/platformNotifications';
import * as activityActions from '../trade/activity-log';
import * as accountHistoryActions from '../trade/account-history';
import * as newsActions from '../trade/news';
import * as strategyAction from '../investing/strategy';
import * as subscriptionActions from '../investing/subscription';
import * as conversionAction from '../investing/conversion';
import * as screenSharingActions from '../sharing/screen';
import * as messageBoxActions from '../message-box';
import AppConfig from '../../AppConfig';
import authService from '../../services/auth-service';


export const UPDATE_DATA = 'TRADE_TRANSPORTS_TRADE_UPDATE_DATA';

function updateData({ quotes, accounts }) {
    return {
        type: UPDATE_DATA,
        payload: {
            quotes,
            accounts
        }
    }
}

/**
 *  Автоматическое обновление нужно так как приходит очень много событий
 *  и какждый раз обновление очень дорого стоит.
 *  Значение 0 - выбрасывать событие немендленно
 * @type {number}
 */
const COLLECT_TIMEOUT = AppConfig.collectUpdatesTimeout;

const COLLECTED_QUOTES_SIZE_SYMBOL = Symbol('collectedQuotesSize');
const COLLECTED_ACCOUNTS_SIZE_SYMBOL = Symbol('collectedAccountsSize');

/**
 * @type {{symbolId: quote}}
 * @private
 */
let _collectedQuotes = createCollectQuotesStore();
let _collectedAccounts = createCollectAccountsStore();

const runFunction = window.setTimeout;

function createCollectQuotesStore() {
    return {
        [COLLECTED_QUOTES_SIZE_SYMBOL]: 0
    }
}

function createCollectAccountsStore() {
    return {
        [COLLECTED_ACCOUNTS_SIZE_SYMBOL]: 0
    }
}

if (COLLECT_TIMEOUT > 0) {
    window.setInterval(function () {
        runFunction(_throwUpdates);
    }, COLLECT_TIMEOUT)
}

export function doRejectTradeByBalanceRequirement(error) {
    return (dispatch, getState) => {
        const accounts = getState().trade.accounts.listToShow;
        const tickers = getState().trade.tickers.list;
        const currentAccount = getState().trade.accounts.current;
        const {isReal, isDemo} = currentAccount;

        const errorData = error.split(';');
        const instrumentId = errorData[1];
        const minBalance = errorData[2];
        const accountCurrency = errorData[3];

        const accordingAccount = accounts.find(
            (account) =>
                account.BaseCurrency === accountCurrency &&
                account.Balance >= +minBalance &&
                account.isReal === isReal &&
                account.isDemo === isDemo
        );
        const ticker = tickers.get(instrumentId);
        const tickerName = ticker.Name;

        if (accordingAccount) {
            const accordingAccountName = accordingAccount.FullName;

            dispatch(
                messageBoxActions.showConfirm({
                    title: lang.t('Trading is blocked'),
                    message: lang.t(
                        'Your wallet balance must be not less than {minBalance} {accountCurrency} to trade with instrument {tickerName}. You can open a trade for this instrument on wallet {accordingAccountName}. Do you want to change your wallet?',
                        {
                            minBalance,
                            accountCurrency,
                            tickerName,
                            accordingAccountName,
                        }
                    ),
                    okCallback: () =>
                        dispatch(
                            accountActions.doChangeAccountNumber(
                                accordingAccount.AccountNumber
                            )
                        ),
                    buttonSubmitLabel: lang.t('Yes'),
                    buttonCancelLabel: lang.t('No'),
                })
            );
        } else {
            let depositAmount = minBalance - currentAccount.Balance;
            depositAmount = lang.number(depositAmount, currentAccount.BaseCurrencyPrecision);

            dispatch(
                messageBoxActions.showConfirm({
                    title: lang.t('Trading is blocked'),
                    message: lang.t(
                        'Your wallet balance must be not less than {minBalance} {accountCurrency} to trade with instrument {tickerName}. You need to deposit your wallet with {depositAmount} {accountCurrency}.',
                        {
                            minBalance,
                            accountCurrency,
                            tickerName,
                            depositAmount,
                        }
                    ),
                    okCallback: () =>
                        dispatch(appActions.showDepositModal(true)),
                    buttonSubmitLabel: lang.t('Deposit'),
                })
            );
        }
    };
}

export function doInit() {
    return new Promise((resolve, reject) => {
        if (transport.connected) {
            resolve(transport);
        }

        transport.connect();
        transport.onConnected = () => {
            if (store.getState().user.auth.isLogged) {
                const user = store.getState().user;
                const requestData = {
                    lang: store.getState().user.settings.language,
                    channel: Transport.CHANNEL_TRADE,
                    SocketId: user.auth.socketId
                };

                if (AppConfig.features.isEnabledAuth2) {
                    transport.requestAuth2({
                        ...requestData,
                        token: authService.getToken(),
                    });
                } else if (user.model.Login) {
                    transport.requestLogin({
                        ...requestData,
                        login: user.model.Login,
                        password: user.model.Password,
                    });
                }

                // на случай если сервер не ответит
                const timeOutId = window.setTimeout(() => {
                    const msg = 'Log in time out';
                    reject(msg);
                }, 20000);

                transport.onLogin = (data) => {
                    clearTimeout(timeOutId);

                    if (data.Login && data.Login === user.model.Login) {
                        resolve(data);
                        store.dispatch(authActions.loginSocketUserSuccess({SocketId: data.SocketId}));
                        store.dispatch(appActions.languageChanged());
                    } else {
                        const msg = lang.t("Invalid Login or password");
                        reject(msg);
                    }
                };
            }

            resolve(transport);
        };
        transport.onError = reject;
        transport.onInstrumentsList = (data) => {
            store.dispatch(tickersActions.doFetched(data));
        };
        transport.onQuote = (data) => {
            if (COLLECT_TIMEOUT) {
                _collectQuote(data);
                return;
            }

            store.dispatch(tickersActions.updateQuote(data));
        };
        transport.onAccountsList = (data) => {
            store.dispatch(accountActions.doFetched(data));
        };
        transport.onAccountUpdate = (data) => {
            if (COLLECT_TIMEOUT) {
                _collectAccount(data);
                return;
            }

            trackingActions.dispatchAction(accountActions.updateAccounts(data));
        };
        transport.onAccountAdd = (data) => {
            trackingActions.dispatchAction(accountActions.updateAccounts(data));
        };
        transport.onOrdersList = (data) => {
            trackingActions.dispatchAction(ordersActions.fetched(data));
        };
        transport.onOrderAdd = (data) => {
            trackingActions.dispatchAction(ordersActions.add(data));
        };
        transport.onOrderUpdate = (data) => {
            const state = store.getState().app.waitOrderNotifications;
            const orderNumbers = Object.keys(data).filter(key => {
                const { OrderType, TradeMode, OrderNumber } = data[key];

                return (OrderType === Types.ORDER_TYPE_LIMIT 
                    || OrderType === Types.ORDER_TYPE_STOP
                    || TradeMode === Types.INSTRUMENT_TRADE_MODE_EXCHANGE
                ) && state.waitUpdate.has(OrderNumber)
            });

            if (orderNumbers.length) {
                store.dispatch(messageBoxActions.showInfo({
                    title: lang.t('Change pending trade'),
                    message: lang.t('The pending trade has been successfully changed'),
                }));
            }

            trackingActions.dispatchAction(ordersActions.update(data));
        };
        transport.onOrderRemove = (data) => {
            const waitOrderNotifications = store.getState().app.waitOrderNotifications;
            const orderNumbers = Object.keys(data).filter(key => {
                const { OrderType, TradeMode, OrderNumber } = data[key];

                return (OrderType === Types.ORDER_TYPE_LIMIT 
                    || OrderType === Types.ORDER_TYPE_STOP
                    || TradeMode === Types.INSTRUMENT_TRADE_MODE_EXCHANGE
                ) && waitOrderNotifications.waitRemove.has(OrderNumber)
            });

            if (waitOrderNotifications.waitRemove.has("group")) {
                let { group: groupOrderNumbers, side } = waitOrderNotifications.waitRemove.get("group");

                const orderNumbers = Object.keys(data).filter(
                    key => {
                        const { OrderType, TradeMode, OrderNumber } = data[key];

                        return (
                            OrderType === Types.ORDER_TYPE_LIMIT
                            || OrderType === Types.ORDER_TYPE_STOP
                            || TradeMode === Types.INSTRUMENT_TRADE_MODE_EXCHANGE
                        ) && groupOrderNumbers.includes(OrderNumber)
                    }
                );
                if (groupOrderNumbers.length === orderNumbers.length) {
                    store.dispatch(appActions.removeWaitOrderNotification('waitRemove', "group"))
    
                    store.dispatch(messageBoxActions.showInfo({
                        title: lang.t('Delete pending trades'),
                        message: lang.t('Pending trades were successfully deleted'),
                    }));
                } else {
                    const leftGroupOrderNumbers = groupOrderNumbers.filter((OrderNumber) => !orderNumbers.includes(OrderNumber));
                    store.dispatch(appActions.addWaitOrderNotification('waitRemove', { group: leftGroupOrderNumbers, side }));
                }
            }
            if (orderNumbers.length) {
                store.dispatch(messageBoxActions.showInfo({
                    title: lang.t('Delete pending trade'),
                    message: lang.t('The pending trade has been successfully deleted'),
                }));
            }

            store.dispatch(ordersActions.doRemoveOrders(data));
        };
        transport.onPositionsList = (data) => {
            trackingActions.dispatchAction(positionsActions.fetched(data));
        };
        transport.onPositionAdd = (data) => {
            trackingActions.dispatchAction(positionsActions.add(data));
        };
        transport.onPositionUpdate = (data) => {
            const waitOrderNotifications = store.getState().app.waitOrderNotifications;
            const orderNumbers = Object.keys(data).filter(
                key => waitOrderNotifications.waitUpdate.has(data[key].OrderNumber)
            );

            if (waitOrderNotifications.waitRemove.has("group")) {
                let {group: groupOrderNumbers, side, netting} = waitOrderNotifications.waitRemove.get("group");

                const orderNumbers = Object.keys(data).filter(
                    key => groupOrderNumbers.includes(data[key].OrderNumber)
                    );
                if (groupOrderNumbers.length === orderNumbers.length) {
                    store.dispatch(appActions.removeWaitOrderNotification('waitRemove', "group"))
    
                    store.dispatch(messageBoxActions.showInfo({
                        title: lang.t(netting ? 'Net trades' : 'Close trade'),
                        message: lang.t(getPositionsGroupSuccessMessageText({side, netting})),
                    }));
                } else {
                    const leftGroupOrderNumbers = groupOrderNumbers.filter((OrderNumber) => !orderNumbers.includes(OrderNumber));
                    store.dispatch(appActions.addWaitOrderNotification('waitRemove', { group: leftGroupOrderNumbers, side, netting }));
                }
            }
            if (orderNumbers.length) {
                orderNumbers.forEach(orderNumber =>
                    store.dispatch(appActions.removeWaitOrderNotification('waitUpdate', orderNumber))
                );

                store.dispatch(messageBoxActions.showInfo({
                    title: lang.t('Change trade'),
                    message: lang.t('The trade has been successfully changed'),
                }));
            }

            trackingActions.dispatchAction(positionsActions.update(data));
        };
        transport.onPositionRemove = (data) => {
            const { waitOrderNotifications, editPositionNumber, partialClosePositionNumber } = store.getState().app;
            const orderNumbers = Object.keys(data).filter(
                key => waitOrderNotifications.waitRemove.has(data[key].OrderNumber)
                );

            if (editPositionNumber) {
                store.dispatch(appActions.showPositionEditModal(''));
            }
            if (partialClosePositionNumber) {
                store.dispatch(appActions.showPartialCloseModal(''));
            }

            if (waitOrderNotifications.waitRemove.has("group")) {
                let {group: groupOrderNumbers, side, netting} = waitOrderNotifications.waitRemove.get("group");

                const orderNumbers = Object.keys(data).filter(
                    key => groupOrderNumbers.includes(data[key].OrderNumber)
                    );
                if (groupOrderNumbers.length === orderNumbers.length) {
                    store.dispatch(appActions.removeWaitOrderNotification('waitRemove', "group"))
    
                    store.dispatch(messageBoxActions.showInfo({
                        title: lang.t(netting ? 'Net trades' : 'Close trade'),
                        message: lang.t(getPositionsGroupSuccessMessageText({side, netting})),
                    }));
                } else {
                    const leftGroupOrderNumbers = groupOrderNumbers.filter((OrderNumber) => !orderNumbers.includes(OrderNumber));
                    store.dispatch(appActions.addWaitOrderNotification('waitRemove', { group: leftGroupOrderNumbers, side, netting }));
                }
            }
            if (orderNumbers.length) {
                orderNumbers.forEach(orderNumber =>
                    store.dispatch(appActions.removeWaitOrderNotification('waitRemove', orderNumber))
                );

                store.dispatch(messageBoxActions.showInfo({
                    title: lang.t('Close trade'),
                    message: lang.t('The trade has been successfully closed'),
                }));
            }

            trackingActions.dispatchAction(positionsActions.remove(data));
        };
        transport.onInstrumentGroupList = (data) => {
            store.dispatch(tickersGroupsActions.fetched(data));
        };
        transport.onCommissionsList = (data) => {
            store.dispatch(commissionsActions.fetched(data));
        };
        transport.onSwapsList = (data) => {
            store.dispatch(swapsActions.fetched(data));
        };
        transport.onCommissionPaymentList = (data) => {
            store.dispatch(commissionPaymentsActions.fetched(data));
        };
        transport.onMarginModelList = (data) => {
            store.dispatch(marginModelsActions.fetched(data));
        };
        transport.onInterchangeList = (data) => {
            store.dispatch(interchangeActions.fetched(data));
        };
        transport.onInterchangeAdd = (data) => {
            store.dispatch(interchangeActions.add(data));
        };
        transport.onInterchangeRemove = (data) => {
            store.dispatch(interchangeActions.remove(data));
        };
        transport.onInterchangeDecode = (data) => {
            store.dispatch(interchangeActions.decode(data));
        };
        transport.onInterchangeExecute = (data) => {
            store.dispatch(interchangeActions.execute(data));
        };
        transport.onTradeSessionList = (data) => {
            store.dispatch(tradeSessionsActions.fetched(data));
        };
        transport.onTradeSessionAddUpdate = (data) => {
            store.dispatch(tradeSessionsActions.update(data));
        };
        transport.onTradeSessionRemove = (data) => {
            store.dispatch(tradeSessionsActions.remove(Object.keys(data).map(id => Number(id))));
        };
        transport.onTechBreak = (data) => {
            store.dispatch(appActions.startMt({
                beginTime: data.BeginTime * 1000,
                endTime: data.EndTime * 1000,
            }));
        };
        transport.onError = (error) => {
        };
        transport.onReject = (errorData) => {
            const error = errorData.Reason;
            const additionalCode = errorData.Additional && errorData.Additional.split(" ")[1].replace(/[^0-9]/g, '');

            if (error === 'InterchangeReject') {
                store.dispatch(interchangeActions.reject(errorData.Additional));
            } else if (error === "InsufficientFunds") {
                store.dispatch(appActions.showInsufficientFundsMessage(true));
            } else if (error === "AccountTradingIsNotAllowed") {
                store.dispatch(appActions.showAccountBlockMessage(true));
            } else if (+additionalCode === +Types.REJECT_OPEN_TRADE_BY_BALANCE_REQUIREMENT) {
                store.dispatch(doRejectTradeByBalanceRequirement(errorData.Additional));
            } else if (+additionalCode === +Types.REJECT_OPEN_TRADE_CLOSED_TRADE_SESSION) {
                store.dispatch(transportActions.reject(lang.t("The trading session is closed")));
            } else {
                const replaceMap = {
                    "TradeServerReject": "The trading session is closed",
                    "Error Code: 37": "The trading session is closed",
                    "Error Code: 11": "The selected instrument is not available for trading in this wallet"
                };

                const replaceError = replaceMap[error] ? replaceMap[error] : error;
                store.dispatch(transportActions.reject(lang.t(replaceError)));
            }

            store.dispatch(appActions.cleanWaitOrderNotification());
        };
        transport.onRejectOrder = (error) => {
            const replaceMap = {
                "": "There was a problem with your request. Please check input data and try again"
            };

            const replaceError = replaceMap[error] ? replaceMap[error] : error;
            store.dispatch(transportActions.rejectOrder(lang.t(replaceError)));
        };
        transport.onRejectPosition = (error) => {
            const replaceMap = {
                "": "Trade opening rejected"
            };

            const replaceError = replaceMap[error] ? replaceMap[error] : error;
            store.dispatch(transportActions.rejectOrder(lang.t(replaceError)));
        };
        transport.onInterchangeList = (data) => {
            store.dispatch(interchangeActions.fetched(data));
        };
        transport.onInterchangeAdd = (data) => {
            store.dispatch(interchangeActions.add(data));
        };
        transport.onInterchangeRemove = (data) => {
            store.dispatch(interchangeActions.remove(data));
        };
        transport.onInterchangeDecode = (data) => {
            store.dispatch(interchangeActions.decode(data));
        };
        transport.onInterchangeExecute = (data) => {
            store.dispatch(interchangeActions.execute(data));
        };

        transport.onActivityLog = (data) => {
            trackingActions.dispatchAction(activityActions.fetched(data));
        };
        transport.onActivityLogUpdate = (data) => {
            const activity = data[0];
            if (activity) {
                switch (activity.EventTypeCode) {
                    case Types.EVENT_TYPE_CODE.MARGIN_CALL: {
                        store.dispatch(appActions.gotMarginCall(activity.ActionId));
                        break;
                    }
                    case Types.EVENT_TYPE_CODE.STOP_OUT: {
                        store.dispatch(appActions.gotStackStopOut({
                            actionId: activity.ActionId,
                            accountNumber: activity.AccountNumber,
                            realizedPnL: activity.RealizedPnL,
                        }));
                        break;
                    }
                    case Types.EVENT_TYPE_CODE.POSITION_CLOSED: {
                        const accauntNumber = data[0].AccountNumber;
                        const closedId = data[0].Id;
                        const state = store.getState();
                        const activePositionsInTools = state.trade.positions.list.filter(position => {
                            return position.OrderNumber !== closedId && position.AccountNumber === accauntNumber;
                        }).size;
                        const stopOutMessage = state.app.showStopOutModals.first(d => d.accauntNumber === accauntNumber);
                        if (!activePositionsInTools && stopOutMessage) {
                            store.dispatch(appActions.showStopOutModal(accauntNumber))
                        }
                        break;
                    }
                    default:
                        break;
                }
            }
            if (activity && activity.EventText && activity.EventText.includes('Matched order is not found')) {
                trackingActions.dispatchAction(interchangeActions.notFound());
            } else {
                trackingActions.dispatchAction(activityActions.add(data[0]));
            }
        };
        transport.onNewsList = (data) => {
            store.dispatch(newsActions.fetched(data));
        };
        transport.onNewsAdd = (data) => {
            store.dispatch(newsActions.add(data[0]));
        };
        transport.onAccountHistory = (data) => {
            store.dispatch(accountHistoryActions.doFetched(data));
        };
        transport.onAccountHistoryUpdate = (data) => {
            trackingActions.dispatchAction(accountHistoryActions.add(data[0]));
        };
        transport.onHistoryQuotes = (data) => {
            store.dispatch(chartAction.doHistoryQuotes(data));
        };
        transport.onHistoryBars = (data) => {
            store.dispatch(chartAction.doHistoryBars(data));
        };
        transport.onSignalProviders = (data) => {
            store.dispatch(strategyAction.fetched(data));
        };
        transport.onSignalSubscriptions = (data) => {
            const oldSubscription = store.getState().investing.subscription.list;
            const newIdMap = getFieldMap(data, 'SetID')

            oldSubscription.forEach(s => {
                if (!newIdMap[s.SetID]) {
                    store.dispatch(subscriptionActions.doPostUnsubscribe(s.SetID));
                }
            });

            store.dispatch(subscriptionActions.doFetched(data));
        };
        transport.onSignalHistory = (data) => {
            store.dispatch(strategyAction.fetchedHistory(data));
        };
        transport.onConversion = (data) => {
            store.dispatch(conversionAction.fetched(data));
        };
        transport.onScreenSharingRequest = (data) => {
            store.dispatch(screenSharingActions.doRequest(data));    
        };
        transport.onScreenSharingAnswer = (data) => {
            store.dispatch(screenSharingActions.doAnswer(data));
        };
        transport.onScreenSharingEnd = () => {
            store.dispatch(screenSharingActions.doEnd(false));
        };
        transport.onScreenSharingCancel = () => {
            store.dispatch(screenSharingActions.doEnd(false));
        };
        transport.onScreenSharingStarted = () => {
            store.dispatch(screenSharingActions.started());
        };
        transport.onRejectSignalSubscription = (errorData) => {
            const error = errorData.Reason || 'Subscription Error';

            store.dispatch(subscriptionActions.subscribingOnStrategy(''));
            store.dispatch(transportActions.reject(lang.t(error)));
        };
        transport.onNotificationAdd = (listData) => {
            store.dispatch(platformNotifications.onNotificationAdd(listData));
        };
    })
}

export function doReconnect() {
    doInit().then(() => {
        transport.reconnectStart(0);
    }, () => { })
}

function _collectQuote(quote) {
    _collectedQuotes[quote.SymbolId] = quote;
    _collectedQuotes[COLLECTED_QUOTES_SIZE_SYMBOL]++;
}

function _collectAccount(data) {
    for (let accountId in data) {
        _collectedAccounts[accountId] = data[accountId];
        _collectedAccounts[COLLECTED_ACCOUNTS_SIZE_SYMBOL]++;
    }
}

function _throwUpdates() {
    let hasUpdates = false;
    let updatedQuotes = null;
    let updatedAccounts = null;

    if (_collectedQuotes[COLLECTED_QUOTES_SIZE_SYMBOL] > 0) {
        hasUpdates = true;
        updatedQuotes = _collectedQuotes;
        delete updatedQuotes[COLLECTED_QUOTES_SIZE_SYMBOL]; // fix for IE
        _collectedQuotes = createCollectQuotesStore();
    }

    if (_collectedAccounts[COLLECTED_ACCOUNTS_SIZE_SYMBOL] > 0) {
        hasUpdates = true;
        updatedAccounts = _collectedAccounts;
        delete updatedAccounts[COLLECTED_ACCOUNTS_SIZE_SYMBOL]; // fix for IE
        _collectedAccounts = createCollectAccountsStore();
    }

    if (hasUpdates) {
        store.dispatch(updateData({
            quotes: updatedQuotes,
            accounts: updatedAccounts
        }));
    }
}