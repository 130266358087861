/**
 * Created by ebondarev
 */
import { Record, Map, List } from 'immutable';

import { store } from '../../store';
import Types from '../../classes/types';
import { getAccountsByType } from './accounts';
import { sort } from '../utils';
import * as actionTypes from '../../actions/trade/orders';
import * as actionTickersTypes from '../../actions/trade/tickers';
import * as chartTypes from '../../actions/trade/chart';
import * as userTypes from '../../actions/user/user';
import * as accountsTypes from '../../actions/trade/accounts';
import * as trackingTypes from '../../actions/tracking/tracking';
import * as transportTradeTypes from '../../actions/transports/trade';
import { OrderModel } from '../../models/order';
import { PositionsGroupModel } from '../../models/positions-group';
import { customerFilter } from '../../utils/filters';

class SummaryOrders {
    unitVolume = 0;
    unitInLots = 0;

    updateFromPosition(order) {
        this.unitVolume += order.UnitVolume;
        this.unitInLots += order.unitInLots;
    }
}

class OrdersStore extends Record({
    list: new Map(),
    listToShow: new Map(),
    isOpenList: new List(),              // list ids to show details in the table
    ordersGroups: new List(),
    replaceOrderList: new Map(),
    status: actionTypes.NOT_FETCHED,
    sortAttribute: 'UTCDateTime',
    sortDirection: Types.SORT_DESC,
    lastUpdate: Date.now(),
    demoAccount: false,
    symbolIdToOrderMap: new Map(),
    summary: new SummaryOrders(),
    activeOrderOnChart: "",
    activeOrderNumber: 0,
    ordersSearch: ''
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }
}

function convertDataToOrdersMap(data, isAdded = false) {
    const tickersStore = store.getState().trade.tickers.list;
    const accountsStore = store.getState().trade.accounts.list;

    return new Map(data)
        .map((orderData) => {
            const order = new OrderModel(orderData);
            order.isAdded = isAdded;
            const ticker = tickersStore.get(order.SymbolId);
            const account = accountsStore.get(order.AccountNumber);
            if (ticker) {
                order.updateAttributesFromQuoteOrTicker(ticker);
                order.precision = ticker.PricePrecision; // need set once
                order.exp1 = ticker.Exp1;
                order.exp2 = ticker.Exp2;
            }

            if (account) {
                order.accountName = account.Name;
                order.accountCurrency = account.BaseCurrency;
            }

            order.unitInLots = order.Amount;

            return order
        })
        .filter(order => {
            const ticker = tickersStore.get(order.SymbolId) || {};
            return ticker.InstrumentTypeName !== Types.INTERCHANGE_INSTRUMENT_TYPE_NAME;
        });
}

function symbolIdToOrderMap(list) {
    return list.map((order) => {
        return {
            OrderNumber: order.OrderNumber,
            SymbolId: order.SymbolId,
        }
    }).groupBy((data) => {
        return data.SymbolId
    })
}

function updateFromQuote(state, quote) {
    if (!state.symbolIdToOrderMap.has(quote.SymbolId)) {
        return false;
    }

    const tickersStore = store.getState().trade.tickers;

    state.symbolIdToOrderMap.get(quote.SymbolId).forEach(function (data) {
        state.list.update(data.OrderNumber, (order) => {
            const ticker = tickersStore.list.get(order.SymbolId);
            order.updateAttributesFromQuoteOrTicker(quote);

            if (ticker) {
                order.bidDiff = ticker.bidDiff;
            }

            return order;
        });
    });

    return true;
}

function listToOrdersGroups(list) {
    let data = {};

    list.forEach(order => {
        if (data[order.SymbolId]) {
            data[order.SymbolId].orderNumbers.push(order.OrderNumber);
            data[order.SymbolId].amount += order.Amount;
            data[order.SymbolId].unitVolume += order.UnitVolume;

            if (data[order.SymbolId].lastOpenTime < order.dateTime) {
                data[order.SymbolId].lastOpenTime = order.dateTime;
            }

            if (data[order.SymbolId].buySell !== Types.SIDE_BUYSELL
                && data[order.SymbolId].buySell !== order.BuySell) {
                data[order.SymbolId].buySell = Types.SIDE_BUYSELL;
            }
        } else {
            data[order.SymbolId] = new PositionsGroupModel({
                symbolId: order.SymbolId,
                amount: order.Amount,
                unitVolume: order.UnitVolume,
                orderNumbers: [order.OrderNumber],
                lastOpenTime: order.dateTime,
                buySell: order.BuySell,
            });
        }
    });

    return sort(List(Object.values(data)), 'lastOpenTime', Types.SORT_DESC);
}

function filterListToShow(list, isDemo) {
    const accounts = getAccountsByType(isDemo);

    return list.filter((order) => {
        return accounts.has(order.AccountNumber)
            && order.OrderType !== Types.ORDER_TYPE_LIMIT_TP
            && order.OrderType !== Types.ORDER_TYPE_STOP_SL;
    });
}

function getSummary(listToShow) {
    const summary = new SummaryOrders();

    listToShow.forEach((order) => {
        summary.updateFromPosition(order);
    });

    return summary;
}

const initialState = new OrdersStore();

export default function orders(state = initialState, action) {
    switch (action.type) {
        case actionTickersTypes.UPDATE_QUOTE:
            const updatedAfterQuote = updateFromQuote(state, action.payload);

            return !updatedAfterQuote ? state : state.merge({
                lastUpdate: Date.now(),
                summary: getSummary(state.listToShow)
            });

        case actionTickersTypes.UPDATE_QUOTES:
        case transportTradeTypes.UPDATE_DATA:
            const updatedData = action.type === transportTradeTypes.UPDATE_DATA
                ? action.payload.quotes : action.payload;

            if (!updatedData) {
                return state;
            }

            let updatedAfterQuotes = false;
            for (let symbolId in updatedData) {
                updatedAfterQuotes = updatedAfterQuotes || updateFromQuote(state, updatedData[symbolId]);
            }

            return !updatedAfterQuotes ? state : state.merge({
                lastUpdate: Date.now(),
                summary: getSummary(state.listToShow)
            });

        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING
            });

        case actionTypes.FETCHED:
            const list = sort(convertDataToOrdersMap(action.payload), state.sortAttribute, state.sortDirection);
            const listFetchedToShow = filterListToShow(list, state.demoAccount);

            return state.merge({
                list,
                listToShow: listFetchedToShow,
                ordersGroups: listToOrdersGroups(listFetchedToShow),
                symbolIdToOrderMap: symbolIdToOrderMap(list),
                status: actionTypes.FETCHED,
                lastUpdate: Date.now(),
                summary: getSummary(listFetchedToShow)
            });
        
        case actionTypes.ORDERS_FILTERS_UPDATED: {
            const appStore = store.getState();
            const ordersFiltersState = appStore.trade.filters.ordersFilters;
            const listToShow = filterListToShow(state.list, state.demoAccount)
                .filter(customerFilter(ordersFiltersState));

            return state.merge({
                listToShow: listToShow,
                ordersGroups: listToOrdersGroups(listToShow),
            });
        }

        case trackingTypes.GET_STORE:
            return (action.data.orders = (action.payload) ? state : initialState);

        case trackingTypes.SET_STORE:
            if (action.payload && action.payload.orders) {
                const ordersData = action.payload.orders;
                const ordersMap = new Map(ordersData.list).map((orderData) => { return new OrderModel(orderData); });
                const dataListToShow = new Map(ordersData.listToShow);
                const ordersMapToShow = sort(ordersMap.filter(order => dataListToShow.has(order.OrderNumber)), state.sortAttribute, state.sortDirection);

                let openedList = new List();
                ordersData.isOpenList.forEach(orderId => {
                    if (ordersMap.has(orderId)) openedList = openedList.push(orderId);
                });

                return state.merge({
                    list: ordersMap,
                    listToShow: ordersMapToShow,
                    ordersGroups: listToOrdersGroups(ordersMapToShow),
                    symbolIdToOrderMap: symbolIdToOrderMap(ordersMap),
                    isOpenList: openedList,
                    lastUpdate: Date.now(),
                    demoAccount: ordersData.demoAccount,
                    summary: getSummary(ordersMapToShow)
                });
            }
            return state;

        case actionTypes.ADD:
            const listAdd = sort(state.list.merge(convertDataToOrdersMap(action.payload, true)), state.sortAttribute, state.sortDirection);
            const listAddToShow = filterListToShow(listAdd, state.demoAccount);

            return state.merge({
                list: listAdd,
                listToShow: listAddToShow,
                ordersGroups: listToOrdersGroups(listAddToShow),
                symbolIdToOrderMap: actionTypes.ADD ?
                    symbolIdToOrderMap(listAdd) : state.symbolIdToOrderMap,
                lastUpdate: Date.now(),
                summary: getSummary(listAddToShow)
            });

        case actionTypes.UPDATE:
            const dataToUpdate = {};
            Object.keys(action.payload).forEach((key) => {
                if (state.list.has(key)) {
                    dataToUpdate[key] = action.payload[key];
                }
            });
            const listUpdate = state.list.merge(convertDataToOrdersMap(dataToUpdate));
            const listUpdateToShow = filterListToShow(listUpdate, state.demoAccount);

            return state.merge({
                list: listUpdate,
                listToShow: listUpdateToShow,
                ordersGroups: listToOrdersGroups(listUpdateToShow),
                symbolIdToOrderMap: actionTypes.ADD ?
                    symbolIdToOrderMap(listUpdate) : state.symbolIdToOrderMap,
                lastUpdate: Date.now(),
                summary: getSummary(listUpdateToShow),
            });

        case actionTypes.ACTIVE_ORDER_ON_CHART: {
            return state.merge({
                activeOrderOnChart: action.payload,
            });
        }

        case actionTypes.UNSET_ACTIVE_ORDER:
        case chartTypes.CHANGE_ACTIVE_TAB: {
            return state.merge({
                activeOrderOnChart: '',
                activeOrderNumber: 0,
            });
        }

        case actionTypes.ADD_REPLACE_ORDER: {
            return state.merge({
                replaceOrderList: state.replaceOrderList.set(action.payload.orderId, action.payload.newOrder),
            });
        }

        case actionTypes.REMOVE_REPLACE_ORDER: {
            return state.merge({
                replaceOrderList: state.replaceOrderList.delete(action.payload),
            });
        }

        case actionTypes.REMOVE: {
            let listAfterRemove = state.list;

            Object.keys(action.payload).forEach(id => {
                listAfterRemove = listAfterRemove.remove(id);
            });

            const appStore = store.getState();
            const ordersFiltersState = appStore.trade.filters.ordersFilters;
            const listAfterRemoveToShow = sort(filterListToShow(listAfterRemove, state.demoAccount)
                .filter(customerFilter(ordersFiltersState)), state.sortAttribute, state.sortDirection);

            // const listAfterRemoveToShow = filterListToShow(listAfterRemove, state.demoAccount);

            return state.merge({
                list: listAfterRemove,
                listToShow: listAfterRemoveToShow,
                ordersGroups: listToOrdersGroups(listAfterRemoveToShow),
                symbolIdToOrderMap: symbolIdToOrderMap(listAfterRemove),
                lastUpdate: Date.now(),
                summary: getSummary(listAfterRemoveToShow)
            });
        }

        case actionTypes.CHANGE_SORT_ATTRIBUTE:
            if (state.sortAttribute === action.payload) {
                return state;
            }

            const listSortedAttribute = sort(state.list, action.payload, state.sortDirection);
            return state.merge({
                list: listSortedAttribute,
                listToShow: filterListToShow(listSortedAttribute, state.demoAccount),
                sortAttribute: action.payload,
            });

        case actionTypes.CHANGE_SORT_DIRECTION:
            if (state.sortDirection === action.payload) {
                return state;
            }

            const listSortedDirection = sort(state.list, state.sortAttribute, action.payload);

            return state.merge({
                list: listSortedDirection,
                listToShow: filterListToShow(listSortedDirection, state.demoAccount),
                sortDirection: action.payload,
            });

        case accountsTypes.CHANGED_CURRENT_ACCOUNT:
            const isDemo = action.payload ? action.payload.isDemo : false;
            const listToShowAfterChangeAccount = filterListToShow(state.list, isDemo);

            return state.merge({
                demoAccount: isDemo,
                listToShow: listToShowAfterChangeAccount,
                ordersGroups: listToOrdersGroups(listToShowAfterChangeAccount),
                lastUpdate: Date.now(),
                summary: getSummary(listToShowAfterChangeAccount)
            });

        case userTypes.LOG_OUT:
            return initialState;

        case actionTypes.TOGGLE_DETAILS_OF_ORDER:
            const orderId = action.payload;
            if (state.isOpenList.includes(orderId)) {
                return state.merge({
                    isOpenList: state.isOpenList.filter(id => id !== orderId)
                });
            } else {
                return state.merge({
                    isOpenList: state.isOpenList.push(orderId)
                });
            }

        case actionTypes.ORDERS_SEARCH:
            return state.merge({
                ordersSearch: action.payload
            });

        default:
            return state;
    }
}
