/**
 * Created by ebondarev
 */
import { lang, logger } from '../services';
import AppConfig from '../AppConfig';
import { isDesktop, isMobile, isMobileTarget } from '../utils/platform';
import { getSoundAction } from '../utils/sound';

import * as accountActions from './trade/accounts';
import * as depositActions from './trade/deposit';
import * as trackingActions from './tracking/tracking';
import Register from '../classes/register';
import Types from '../classes/types';

export const NOT_INITIALIZED = "APP_NOT_INITIALIZED";
export const DOWNLOADING = "APP_DOWNLOADING";
export const DOWNLOADED = "APP_DOWNLOADED";
export const LOST_WS_CONNECTION = "APP_LOST_WS_CONNECTION";
export const READY = "APP_READY";
export const TRACKING = "TRACKING";
export const SHOW_TABS_MENU = "APP_SHOW_TABS_MENU";
export const ACTIVE_TAB = "APP_ACTIVE_TAB";
export const ACTIVE_PANEL_MENU = "APP_ACTIVE_PANEL_MENU";
export const ACTIVE_MOBILE_PANEL_MENU = "APP_ACTIVE_MOBILE_PANEL_MENU";
export const HEADER_TITLE = "APP_HEADER_TITLE";
export const PAGE_NAME = "APP_PAGE_NAME";
export const PANEL_MENU_ACTIONS = "APP_PANEL_MENU_ACTIONS";
export const USER_NOTIFICATION_CLEAR = "APP_USER_NOTIFICATION_CLEAR";
export const RESET_IS_SUCCESSFUL_TRADE = 'RESET_IS_SUCCESSFUL_TRADE';
export const SET_LANGUAGE = "APP_SET_LANGUAGE";
export const LANGUAGE_CHANGED = "APP_LANGUAGE_CHANGED";
export const LANGUAGE_CHANGING = "APP_LANGUAGE_CHANGING";
export const SHOW_SETTINGS_GENERAL = "APP_SHOW_SETTINGS_GENERAL";
export const SHOW_INSTRUMENT_DETAILS = "APP_SHOW_INTRUMENT_DETAILS";
export const SHOW_INSTRUMENT_LIST = "APP_SHOW_INTRUMENT_LIST";
export const SHOW_ACTIVITY_LOG_MODAL = "SHOW_ACTIVITY_LOG_MODAL";
export const SHOW_HISTORY_MODAL = "SHOW_HISTORY_MODAL";
export const SHOW_CONSOLIDATED_REPORT_MODAL = "SHOW_CONSOLIDATED_REPORT_MODAL";
export const SHOW_WITHDRAW_MODAL = "APP_SHOW_WITHDRAW_MODAL";
export const SHOW_DEPOSIT_MODAL = "APP_SHOW_DEPOSIT_MODAL";
export const SHOW_DEPOSIT_BXC_MODAL = "APP_SHOW_DEPOSIT_BXC_MODAL";
export const SHOW_DEPOSIT_DEMO_ACCOUNT = "APP_SHOW_DEPOSIT_DEMO_ACCOUNT";
export const SHOW_RISK_FREE_MODAL = "APP_SHOW_RISK_FREE_MODAL";
export const SHOW_SUMSUB_KYC_MODAL = "APP_SHOW_SUMSUB_KYC_MODAL";
export const SHOW_USER_MENU = "APP_SHOW_USER_MENU";
export const START_MT = "APP_START_MT";
export const HIDE_MT_MESSAGE = "APP_HIDE_MT_MESSAGE";
export const REDIRECT_TO = "APP_REFIRECT_TO";
export const CHANGE_TRADE_MODE = 'APP_CHANGE_TRADE_MODE';
export const STOP_ANIMATED_COMPONENTS_ANIMATION = 'APP_STOP_ANIMATED_COMPONENTS_ANIMATION';
export const SHOW_MARGIN_HISTORY_MODAL = "SHOW_MARGIN_HISTORY_MODAL";
export const SHOW_ON_BOARDING_MODAL = "SHOW_ON_BOARDING_MODAL";
export const SHOW_ACCOUNT_REGISTER_MODAL = "SHOW_ACCOUNT_REGISTER_MODAL";
export const SHOW_CREATE_ACCOUNT_MODAL = "SHOW_CREATE_ACCOUNT_MODAL";
export const SHOW_CHANGE_PASSWORD_MODAL = "SHOW_CHANGE_PASSWORD_MODAL";
export const SHOW_INTERCHANGE_MODAL = "SHOW_INTERCHANGE_MODAL";
export const SHOW_TWOFA_MODAL = "SHOW_TWOFA_MODAL";
export const SHOW_SECURITY_MODAL = "SHOW_SECURITY_MODAL";
export const SHOW_EMAIL_TWOFA_MODAL = "SHOW_EMAIL_TWOFA_MODAL";
export const SHOW_TRANSACTION_HISTORY_MODAL = 'SHOW_TRANSACTION_HISTORY_MODAL';
export const SHOW_EXCHANGE_HISTORY_MODAL = 'SHOW_EXCHANGE_HISTORY_MODAL';
export const SHOW_APPROPRIATENESS_TEST_MODAL = "SHOW_APPROPRIATENESS_TEST_MODAL";
export const SHOW_WALLETS_MODAL = "SHOW_WALLETS_MODAL";
export const SHOW_REAL_WALLET_NOT_EXIST_MODAL = "SHOW_REAL_WALLET_NOT_EXIST_MODAL";
export const SHOW_PROFILE_MODAL = "SHOW_PROFILE_MODAL";
export const SHOW_PLATFORM_NOTIFICATIONS_MODAL = "SHOW_PLATFORM_NOTIFICATIONS_MODAL";
export const SET_INVESTING_TAB = "SET_INVESTING_TAB";
export const SET_INVESTING_ID = "SET_INVESTING_ID";
export const SHOW_KYC_FINISH_NOTIFICATION = "SHOW_KYC_FINISH_NOTIFICATION";
export const SHOW_CALL_BACK_FORM = "SHOW_CALL_BACK_FORM";
export const SHOW_ACCOUNT_BLOCK_MESSAGE = "SHOW_ACCOUNT_BLOCK_MESSAGE";
export const SHOW_TRADE_ON_DEMO_MODAL = "SHOW_TRADE_ON_DEMO_MODAL";
export const CHART_CONTROLS_ELEMENT_IS_HOVER = "CHART_CONTROLS_ELEMENT_IS_HOVER";
export const SET_INIT_STATE = "SET_INIT_STATE";
export const PLAY_SOUND = 'PLAY_SOUND';
export const GOTO_PAGE = 'APP_GOTO_PAGE';
export const OPEN_SIDE_PANEL = 'APP_OPEN_SIDE_PANEL';
export const CLOSE_SIDE_PANEL = 'APP_CLOSE_SIDE_PANEL';
export const SET_SIDE_PANEL_COMPONENT = 'APP_SET_SIDE_PANEL_COMPONENT';
export const SHOW_CHART_SETTINGS_DIALOG = "SHOW_CHART_SETTINGS_DIALOG";
export const SHOW_INDICATORS_MODAL = "SHOW_INDICATORS_MODAL";
export const SHOW_DRAW_OBJECTS_MODAL = "SHOW_DRAW_OBJECTS_MODAL";
export const SHOW_NOTIFICATION_MESSAGE = "SHOW_NOTIFICATION_MESSAGE";
export const CLEAR_NOTIFICATION_MESSAGE = "CLEAR_NOTIFICATION_MESSAGE";
export const SHOW_CONFIRMATION_MESSAGE = "SHOW_CONFIRMATION_MESSAGE";
export const HIDE_CONFIRMATION_MESSAGE = "HIDE_CONFIRMATION_MESSAGE";
export const SHOW_CLIENT_WRONG_TIME_MESSAGE = "SHOW_CLIENT_WRONG_TIME_MESSAGE";
export const DISABLE_PASSWORD_CHANGE_MODAL_CLOSE = "DISABLE_PASSWORD_CHANGE_MODAL_CLOSE";
export const SET_FULLSCREEN_DIV_ID = "SET_FULLSCREEN_DIV_ID";
export const SET_MOBILE_ROUTE = "SET_MOBILE_ROUTE";
export const TABS_MENU_CLICK = "APP_TABS_MENU_CLICK";
export const GOT_MARGIN_CALL = "GOT_MARGIN_CALL";
export const CLOSE_MARGIN_CALL_MODAL = "CLOSE_MARGIN_CALL_MODAL";
export const GOT_STOP_OUT = "GOT_STOP_OUT";
export const SHOW_STOP_OUT_MODAL = "SHOW_STOP_OUT_MODAL";
export const CLOSE_STOP_OUT_MODAL = "CLOSE_STOP_OUT_MODAL";
export const SHOW_SCREEN_SHARING_MESSAGE_REQUEST = "SHOW_SCREEN_SHARING_MESSAGE_REQUEST";
export const SHOW_SCREEN_SHARING_MESSAGE_END = "SHOW_SCREEN_SHARING_MESSAGE_END"; 
export const SHOW_INSUFFICIENT_MESSAGE = "SHOW_INSUFFICIENT_MESSAGE";
export const SHOW_AUTO_LOGOUT_MESSAGE = "SHOW_AUTO_LOGOUT_MESSAGE";
export const SET_RUNNING_AUTO_LOGOUT_TIMER = "SET_RUNNING_AUTO_LOGOUT_TIMER";
export const ADD_WAIT_ORDER_NOTIFICATION = "ADD_WAIT_ORDER_NOTIFICATION";
export const REMOVE_WAIT_ORDER_NOTIFICATION = 'REMOVE_WAIT_ORDER_NOTIFICATION';
export const CLEAN_WAIT_ORDER_NOTIFICATION = "CLEAN_WAIT_ORDER_NOTIFICATION ";
export const SET_WAITING_RESULT = "SET_WAITING_RESULT";
export const SHOW_ACCOUNT_MENU_MODAL = "SHOW_ACCOUNT_MENU_MODAL";
export const SHOW_SETTINGS_MENU_MODAL = "SHOW_SETTINGS_MENU_MODAL";
export const SHOW_ACCESS_KEYS_MODAL = "SHOW_ACCESS_KEYS_MODAL";
export const BLOCK_OUTSIDE_CLICK_CLOSE = "BLOCK_OUTSIDE_CLICK_CLOSE";
export const BLOCK_NAVIGATION_MENU = "BLOCK_NAVIGATION_MENU";
export const ADD_ADVANCED_SEARCH = "ADD_ADVANCED_SEARCH";
export const LOAD_SEARCH_REQUESTS = "LOAD_SEARCH_REQUESTS";
export const CHANGE_COLOR_THEME = "CHANGE_COLOR_THEME";
export const SHOW_NEWS_LIST_MODAL = "SHOW_NEWS_LIST_MODAL";
export const SHOW_ECONOMIC_CALENDAR_MODAL = "SHOW_ECONOMIC_CALENDAR_MODAL";
export const SHOW_INVESTING_MODAL = "SHOW_INVESTING_MODAL";
export const SHOW_PENDING_ORDER_MODAL = "SHOW_PENDING_ORDER_MODAL";
export const SHOW_TWOFA_METHODS_MODAL = "SHOW_TWOFA_METHODS_MODAL";
export const SHOW_POSITIONS_MENU_MODAL = "SHOW_POSITIONS_MENU_MODAL";
export const SHOW_ORDERS_MENU_MODAL = "SHOW_ORDERS_MENU_MODAL";
export const SHOW_ASSETS_MODAL = "SHOW_ASSETS_MODAL";
export const SHOW_FAVORITES_MODAL = "SHOW_FAVORITES_MODAL";
export const SHOW_TRADES_MODAL = "SHOW_TRADES_MODAL";
export const SHOW_POSITION_EDIT_MODAL = "SHOW_POSITION_EDIT_MODAL";
export const SHOW_PARTIAL_CLOSE_MODAL = "SHOW_PARTIAL_CLOSE_MODAL";
export const SET_WALLET_MODAL_ELEMENT = "SET_WALLET_MODAL_ELEMENT";
export const SHOW_OPEN_ORDER_MODAL = "SHOW_OPEN_ORDER_MODAL";
export const SHOW_EDIT_ORDER_MODAL = "SHOW_EDIT_ORDER_MODAL";
export const SET_HISTORY_DETAIL_ID = "SET_HISTORY_DETAIL_ID";

export function changeMobileRoute(route) {
    return {
        type: SET_MOBILE_ROUTE,
        payload: route,
    }
}

export function gotMarginCall(actionId) {
    return {
        type: GOT_MARGIN_CALL,
        payload: actionId,
    }
}

export function closeMarginCallModal() {
    return {
        type: CLOSE_MARGIN_CALL_MODAL,
    }
}

export function gotStackStopOut({actionId, accountNumber, realizedPnL}) {
    return {
        type: GOT_STOP_OUT,
        payload: {
            actionId,
            accountNumber,
            realizedPnL,
        },
    }
}

export function showStopOutModal(accountNumber) {
    return {
        type: SHOW_STOP_OUT_MODAL,
        payload: {
            accountNumber,
        }
    }
}

export function closeStopOutModal(actionId) {
    return {
        type: CLOSE_STOP_OUT_MODAL,
        payload: {
            actionId
        }
    }
}

function initLanguage(setLang) {
    try {
        window.document.documentElement.setAttribute("lang", setLang);
    } catch (e) { }

    return lang.init(setLang).catch((e) => {
        logger.error('Cannot loading lang: ' + setLang);
    });
}

export function setLanguage(lang) {
    return {
        type: SET_LANGUAGE,
        payload: lang,
        promise: initLanguage(lang)
    }
}

export function languageChanging() {
    return {
        type: LANGUAGE_CHANGING,
    }
}

export function languageChanged() {
    return {
        type: LANGUAGE_CHANGED,
    }
}

export function changeTradeMode(mode) {
    if (!AppConfig.features.allowChangeTradeMode) {
        return {
            type: "",
        };
    }

    return {
        type: CHANGE_TRADE_MODE,
        payload: mode,
    }
}

export function stopAnimatedComponentsAnimation() {
    return {
        type: STOP_ANIMATED_COMPONENTS_ANIMATION
    }
}

export function notInitialized() {
    return {
        type: NOT_INITIALIZED,
    }
}

export function downloading() {
    return {
        type: DOWNLOADING,
    }
}

export function downloaded() {
    return {
        type: DOWNLOADED,
    }
}

export function ready() {
    return {
        type: READY,
    }
}

export function lostWSConnection() {
    return {
        type: LOST_WS_CONNECTION,
    }
}

export function activeMobilePanelMenu(value) {
    return {
        type: ACTIVE_MOBILE_PANEL_MENU,
        payload: !!value
    }
}

export function activePanelMenu(value) {
    return {
        type: ACTIVE_PANEL_MENU,
        payload: !!value
    }
}

export function showTabsMenu(value = true) {
    return {
        type: SHOW_TABS_MENU,
        payload: value
    }
}

export function activeTab(value) {
    return {
        type: ACTIVE_TAB,
        payload: value
    }
}

export function headerTitle(value) {
    return {
        type: HEADER_TITLE,
        payload: value
    }
}

export function openSidePanel(id) {
    return {
        type: OPEN_SIDE_PANEL,
        payload: id,
    }
}

export function closeSidePanel() {
    return {
        type: CLOSE_SIDE_PANEL,
    }
}

export function setSidePanelComponent(sidePanelComponent) {
    return {
        type: SET_SIDE_PANEL_COMPONENT,
        payload: sidePanelComponent
    }
}

export function pageName(value) {
    return {
        type: PAGE_NAME,
        payload: value
    }
}

export function panelMenuActions(menuActions) {
    return {
        type: PANEL_MENU_ACTIONS,
        payload: menuActions
    }
}

export function playSound(soundId) {
    const meta = getSoundAction(soundId);

    return {
        type: PLAY_SOUND,
        meta,
    }
}

export function clearUserNotification() {
    return {
        type: USER_NOTIFICATION_CLEAR,
    }
}

export function resetIsSuccessfulTrade() {
    return {
        type: RESET_IS_SUCCESSFUL_TRADE,
    }
}

export function goToPage(page) {
    return {
        type: GOTO_PAGE,
        redirect: page,
    }
}

export function doChartControlsHover(value = false) {
    return {
        type: CHART_CONTROLS_ELEMENT_IS_HOVER,
        payload: !!value
    }
}

export function showGlobalSettings(isShow = false, group = 'general') {
    return {
        type: SHOW_SETTINGS_GENERAL,
        payload: { isShow, group },
    }
}

export function showCallBackForm(value = false) {
    return {
        type: SHOW_CALL_BACK_FORM,
        payload: !!value
    }
}

export function showActivityLogModal(value = false) {
    return {
        type: SHOW_ACTIVITY_LOG_MODAL,
        payload: !!value
    }
}

export function showHistoryModal(value = false) {
    return {
        type: SHOW_HISTORY_MODAL,
        payload: !!value
    }
}

export function showConsolidatedReport(value = false) {
    return {
        type: SHOW_CONSOLIDATED_REPORT_MODAL,
        payload: !!value
    }
}

export function showMarginHistoryModal(value = false) {
    return {
        type: SHOW_MARGIN_HISTORY_MODAL,
        payload: !!value
    }
}

export function showInstrumentDetails(value = false) {
    return {
        type: SHOW_INSTRUMENT_DETAILS,
        payload: !!value
    }
}

export function showInstrumentList(isShowInstrumentDetails = false, instrumentListLeftPosition = 0, instrumentListRightPosition = 'unset') {
    let action = {
        type: SHOW_INSTRUMENT_LIST,
        payload: { isShowInstrumentDetails, instrumentListLeftPosition, instrumentListRightPosition }
    };

    if (isMobile && isShowInstrumentDetails) {
        action.redirect = '/instruments';
    }

    return action;
}

export function showWithdrawModal(value = false) {
    let action = {
        type: SHOW_WITHDRAW_MODAL,
        payload: !!value
    };

    if (value && isMobileTarget) {
        action.redirect = '/withdraw';
    }

    return action;
}

export function showDepositModal(value = false) {
    let action = {
        type: SHOW_DEPOSIT_MODAL,
        payload: !!value
    };

    if (value && isMobileTarget) {
        action.redirect = '/deposit';
    }

    if (!value && process.env.REACT_APP_TARGET === 'mobile') {
        action.redirect = '/';
    }

    return action;
}

export function showWalletsModal(value = false) {
    return {
        type: SHOW_WALLETS_MODAL,
        payload: !!value
    }
}

export function showProfileModal(value = false) {
    return {
        type: SHOW_PROFILE_MODAL,
        payload: !!value
    }
}

export function showPlatformNotificationsModal(value = false) {
    return {
        type: SHOW_PLATFORM_NOTIFICATIONS_MODAL,
        payload: !!value
    }
}
export function showDepositBXCModal(value = false) {
    return {
        type: SHOW_DEPOSIT_BXC_MODAL,
        payload: !!value
    }
}

export function showChartSettingsDialog(value = false) {
    return {
        type: SHOW_CHART_SETTINGS_DIALOG,
        payload: value
    }

}

export function showIndicatorsModal(value = false) {
    return {
        type: SHOW_INDICATORS_MODAL,
        payload: !!value
    }
}

export function showDrawObjectsModal(value = false) {
    return {
        type: SHOW_DRAW_OBJECTS_MODAL,
        payload: !!value
    }
}

export function showDepositDemoAccount(value = true) {
    let action = {
        type: SHOW_DEPOSIT_DEMO_ACCOUNT,
        payload: value
    };

    if (value && isMobileTarget) {
        action.redirect = '/demo-deposit';
    }

    return action;
}

export function showFreeModal(value = false) {
    return {
        type: SHOW_RISK_FREE_MODAL,
        payload: !!value
    }
}

export function showSumsubKycModal(value = true) {
    return {
        type: SHOW_SUMSUB_KYC_MODAL,
        payload: !!value
    }
}

export function showUserMenu(value = true) {
    return {
        type: SHOW_USER_MENU,
        payload: !!value
    }
}

export function startMt(data) {
    return {
        type: START_MT,
        payload: data
    }
}

export function tabsMenuClick(value = true) {
    return {
        type: TABS_MENU_CLICK,
        payload: value,
    }
}

export function hideMtMessage() {
    return {
        type: HIDE_MT_MESSAGE,
    }
}

export function redirectTo(url) {
    return {
        type: REDIRECT_TO,
        payload: url,
    }
}

export function showOnBoardingModal(value = false) {
    return {
        type: SHOW_ON_BOARDING_MODAL,
        payload: !!value
    }
}

export function showAppropriatenessTestModal(value = false) {
    return {
        type: SHOW_APPROPRIATENESS_TEST_MODAL,
        payload: !!value
    }
}

export function showAccountRegisterModal(value = false) {
    return {
        type: SHOW_ACCOUNT_REGISTER_MODAL,
        payload: !!value
    }
}

export function showCreateAccountModal(value = false) {
    return {
        type: SHOW_CREATE_ACCOUNT_MODAL,
        payload: !!value
    }
}

export function showChangePasswordModal(value = false) {
    return {
        type: SHOW_CHANGE_PASSWORD_MODAL,
        payload: !!value
    }
}

export function showInterchangeModal(value = false) {
    return {
        type: SHOW_INTERCHANGE_MODAL,
        payload: !!value
    }
}

export function showTwoFAModal(value = false) {
    return {
        type: SHOW_TWOFA_MODAL,
        payload: !!value
    }
}

export function showTwoFAMethodsModal(value = false) {
    return {
        type: SHOW_TWOFA_METHODS_MODAL,
        payload: !!value
    }
}

export function showPositionsMenuModal(value = false) {
    return {
        type: SHOW_POSITIONS_MENU_MODAL,
        payload: !!value
    }
}

export function showOrdersMenuModal(value = false) {
    return {
        type: SHOW_ORDERS_MENU_MODAL,
        payload: !!value
    }
}

export function showAssetsModal(value = false) {
    return {
        type: SHOW_ASSETS_MODAL,
        payload: !!value
    }
}

export function showFavoritesModal(value = false) {
    return {
        type: SHOW_FAVORITES_MODAL,
        payload: !!value
    }
}

export function showTradesModal(value = false) {
    return {
        type: SHOW_TRADES_MODAL,
        payload: !!value
    }
}

export function showPositionEditModal(orderNumber = '') {
    return {
        type: SHOW_POSITION_EDIT_MODAL,
        payload: orderNumber
    }
}

export function showPartialCloseModal(orderNumber = '') {
    return {
        type: SHOW_PARTIAL_CLOSE_MODAL,
        payload: orderNumber
    }
}

export function showSecurityModal(isShow = false, group = 'changePassword') {
    return {
        type: SHOW_SECURITY_MODAL,
        payload: { isShow, group },
    }
}

export function showEmailTwoFAModal(value = false) {
    return {
        type: SHOW_EMAIL_TWOFA_MODAL,
        payload: !!value
    }
}

export function showTransactionHistoryModal(value = true) {
    return {
        type: SHOW_TRANSACTION_HISTORY_MODAL,
        payload: !!value
    }
}

export function showExchangeHistoryModal(value = true) {
    return {
        type: SHOW_EXCHANGE_HISTORY_MODAL,
        payload: !!value
    }
}

export function showKycFinishNotification(value = false) {
    return {
        type: SHOW_KYC_FINISH_NOTIFICATION,
        payload: !!value
    }
}

export function setInvestingTab(tab) {
    return {
        type: SET_INVESTING_TAB,
        payload: tab
    }
}

export function setInvestingId(id) {
    return {
        type: SET_INVESTING_ID,
        payload: id
    }
}

export function showNotificationMessage(notificationMessage) {
    return {
        type: SHOW_NOTIFICATION_MESSAGE,
        payload: notificationMessage
    }
}

export function showAccountMenuModal(value = false) {
    return {
        type: SHOW_ACCOUNT_MENU_MODAL,
        payload: !!value
    }
}

export function showAccountBlockMessage(value = false) {
    return {
        type: SHOW_ACCOUNT_BLOCK_MESSAGE,
        payload: !!value
    }
}

export function showTradeOnDemoModal(value = false) {
    return {
        type: SHOW_TRADE_ON_DEMO_MODAL,
        payload: !!value
    }
}

export function clearNotificationMessage() {
    return {
        type: CLEAR_NOTIFICATION_MESSAGE
    }
}

export function showConfirmationMessage(confirmationMessage) {
    return {
        type: SHOW_CONFIRMATION_MESSAGE,
        payload: confirmationMessage
    }
}

export function hideConfirmationMessage(confirmationMessage) {
    return {
        type: HIDE_CONFIRMATION_MESSAGE,
        payload: confirmationMessage
    }
}

export function showScreenSharingRequestMessage(value = false) {
    return {
        type: SHOW_SCREEN_SHARING_MESSAGE_REQUEST,
        payload: value,
    }
}

export function showScreenSharingEndMessage(value = false) {
    return {
        type: SHOW_SCREEN_SHARING_MESSAGE_END,
        payload: value,
    }
}

export function showInsufficientFundsMessage(value = false) {
    return {
        type: SHOW_INSUFFICIENT_MESSAGE,
        payload: value,
    }
}

export function setInitState() {
    return {
        type: SET_INIT_STATE,
    }
}

export function showClientWrongTimeMessage() {
    return {
        type: SHOW_CLIENT_WRONG_TIME_MESSAGE,
    }
}

export function setFullScreenDivId(fullScreenDivId) {
    return {
        type: SET_FULLSCREEN_DIV_ID,
        payload: fullScreenDivId,
    }
}

export function disablePasswordChangeModalClose(isDisabledPasswordChangeModalClose) {
    return {
        type: DISABLE_PASSWORD_CHANGE_MODAL_CLOSE,
        payload: isDisabledPasswordChangeModalClose,
    }
}

export function showAutoLogOutMessage(value) {
    return {
        type: SHOW_AUTO_LOGOUT_MESSAGE,
        payload: value,
    }
}

export function setRunningAutoLogOutTimer(value) {
    return {
        type: SET_RUNNING_AUTO_LOGOUT_TIMER,
        payload: value,
    }
}

export function setWaitingResult(value) {
    return {
        type: SET_WAITING_RESULT,
        payload: value,
    }
}

export function addWaitOrderNotification(type, id) {
    return {
        type: ADD_WAIT_ORDER_NOTIFICATION,
        payload: {
            type,
            id,
        }
    }
}

export function removeWaitOrderNotification(type, id) {
    return {
        type: REMOVE_WAIT_ORDER_NOTIFICATION,
        payload: {
            type,
            id,
        }
    }
}

export function cleanWaitOrderNotification() {
    return {
        type: CLEAN_WAIT_ORDER_NOTIFICATION,
    }
}

export function doShowDepositForNewAccount(account) {
    return (dispatch, getState) => {
        const userInfo = getState().user.info.model;
        return dispatch(accountActions.doCreateAccountByUserId({
            userId: userInfo.Id,
            currency: account.BaseCurrency,
            strLeverage: AppConfig.createAccount.autoCreateLeverage,
        })).then(
            accountNumber => {
                if (accountNumber) {
                    setTimeout(() => {
                        const account = getState().trade.accounts.list.get(accountNumber);

                        if (account) {
                            dispatch(depositActions.changeAccount(account));
                            dispatch(showDepositModal(true));
                        }
                    }, 1000);
                }

                return accountNumber;
            }
        );
    }
}

/**
 * Show deposit if account balance is zero
 * @param {AccountModel} account
 */
export function doShowDepositIfZeroBalance(account) {
    return (dispatch, getState) => {
        const { isDisabledPasswordChangeModalClose } = getState().app;
        const { isEnableDemoMode, showDepositIfZeroBalance } = AppConfig.features;

        if (showDepositIfZeroBalance && !isEnableDemoMode && !trackingActions.isTrackMasterMode()
            && !isDisabledPasswordChangeModalClose && account.isReal && account.Balance <= 0) {
            dispatch(showDepositModal(true));
        }
    }
}

/**
 * Show Deposit Demo Account Modal for given demo account
 * @param {string} accountNumber
 */
export function doShowDepositDemoAccount(accountNumber) {
    return (dispatch, getState) => {
        dispatch(depositActions.changeDemoAccountNumber(accountNumber));
        dispatch(showDepositDemoAccount(true));
    }
}

export function showSettingsMenuModal(value = false) {
    return {
        type: SHOW_SETTINGS_MENU_MODAL,
        payload: !!value
    }
}

export function showAccessKeysModal(value = false) {
    return {
        type: SHOW_ACCESS_KEYS_MODAL,
        payload: !!value
    }
}

export function blockOutsideClickClose(value = false) {
    return {
        type: BLOCK_OUTSIDE_CLICK_CLOSE,
        payload: !!value
    }
}

export function blockNavigationMenu(value = false) {
    return {
        type: BLOCK_NAVIGATION_MENU,
        payload: !!value
    }
}

export function addAdvanceSearch(category, request) {
    return {
        type: ADD_ADVANCED_SEARCH,
        payload: {
            category,
            request,
        }
    }
}

export function loadSearchRequests() {
    return {
        type: LOAD_SEARCH_REQUESTS,
    }
}
function changeColorTheme(theme) {
    return {
        type: CHANGE_COLOR_THEME,
        payload: theme,
    };
}

export function doChangeColorTheme(theme) {
    return (dispatch, getState) => {
        const { isChangeDesktopTheme, isChangeMobileTheme, themeColors } = AppConfig.site;
        const isChangeThemeAllowed =
            (isDesktop && isChangeDesktopTheme) ||
            (isMobile && isChangeMobileTheme);

        if (isChangeThemeAllowed) {
            const REGISTER_NAME = 'settings';
            const settings = getState().user.settings;

            if (isDesktop) {
                settings.desktopTheme = theme;
            }

            if (isMobile) {
                settings.mobileTheme = theme;
            }

            const allThemes = Object.values(Types.THEMES).map(theme => themeColors[theme]).flat();
            const removeThemes = [...new Set(allThemes)];

            document.body.classList.remove(...removeThemes);
            document.body.classList.add(theme === Types.THEMES.dark ? settings.themeDark : settings.themeLight);
            Register.set(REGISTER_NAME, settings);

            return dispatch(changeColorTheme(theme));
        }

        const { desktopTheme, mobileTheme } = AppConfig.site;
        const defaultTheme = isDesktop ? desktopTheme : mobileTheme;

        document.body.classList.add(themeColors[defaultTheme][0]);

        dispatch(changeColorTheme(defaultTheme));
    };
}

export function showNewsListModal(value = false) {
    return {
        type: SHOW_NEWS_LIST_MODAL,
        payload: !!value
    };
}

export function showInvestingModal(value = false) {
    return {
        type: SHOW_INVESTING_MODAL,
        payload: !!value
    };
}

export function showEconomicCalendarModal(value = false) {
    return {
        type: SHOW_ECONOMIC_CALENDAR_MODAL,
        payload: !!value
    };
}

export function showPendingOrderModal(show = false, params = {}) {
    return {
        type: SHOW_PENDING_ORDER_MODAL,
        payload: {
            show: !!show,
            params
        }
    }
}

export function showOpenOrderModal(show = false, params = {}) {
    return {
        type: SHOW_OPEN_ORDER_MODAL,
        payload: {
            show: !!show,
            params
        }
    }
}

export function showEditOrderModal(orderNumber = '') {
    return {
        type: SHOW_EDIT_ORDER_MODAL,
        payload: orderNumber
    }
}

export function setWalletModalElement(walletModalElement = '') {
    return {
        type: SET_WALLET_MODAL_ELEMENT,
        payload: walletModalElement
    }
}

export function showRealWalletNotExistModal(walletModalElement = Types.WALLET_WINDOW.EMPTY_WALLET) {
    return {
        type: SHOW_REAL_WALLET_NOT_EXIST_MODAL,
        payload: walletModalElement
    }
}

export function setHistoryDetailId(id = '') {
    return {
        type: SET_HISTORY_DETAIL_ID,
        payload: id
    }
}