/**
 * Created by ebondarev
 */
import FetchApi from './fetchApi';
import ErrorHandler from './errorHandler';
import Logger from './logger';

class Api {
    static defaultHeaders = {
        'Content-Type': 'application/json'
    };

    _logger = null;
    _errorHandler = null;
    _defaultData = {};
    _baseUrl = '';

    constructor({errorHandler = null, logger = null, defaultData = {}, baseUrl = ''} = {}) {
        if (logger instanceof Logger) {
            this._logger = logger;
        }

        if (errorHandler instanceof ErrorHandler) {
            this._errorHandler = errorHandler;
        }

        if (typeof defaultData === 'object') {
            this._defaultData = defaultData;
        }

        this._baseUrl = baseUrl;
    }

    get(url, params = {}) {
        if ((this._errorHandler && this._errorHandler.blockRequests)
            || !navigator.onLine) {
            const error = navigator.onLine ? undefined : {name:'TypeError', message: 'Failed to fetch'};

            this._errorHandler.onCatch(error);
            return Promise.resolve();
        }
        
        const {
            data = {},
            headers = {},
            timeout = 0 // no timeout
        } = params;

        url = Api.normalizeUrl(url);
        const requestData = {...this._defaultData, ...data};
        const requestHeaders = {...Api.defaultHeaders, ...headers};
        url = this._baseUrl + url;

        return new FetchApi().get(url, {
            headers: requestHeaders,
            params: requestData,
            timeout
        }).catch(err => {
            this._logger && this._logger.error(err);
            this._errorHandler && this._errorHandler.onCatch(err);
            return err;
        });
    }

    /**
     * @param url {string|Array}
     * @param body {Object}
     * @param params {Object}
     * @returns {any}
     */
    post(url, body = {}, params = {}) {
        if ((this._errorHandler && this._errorHandler.blockRequests)
            || !navigator.onLine) {
            const error = navigator.onLine ? undefined : {name:'TypeError', message: 'Failed to fetch'};

            this._errorHandler.onCatch(error);
            return Promise.resolve();
        }

        const {
            headers = {},
            timeout = 0
        } = params;

        url = this._baseUrl + Api.normalizeUrl(url);
        const requestData = typeof body === 'object' && body !== null
            ? {...this._defaultData, ...body}
            : body;
        const requestHeaders = {...Api.defaultHeaders, ...headers};

        return new FetchApi().post(url, requestData, {
            headers: requestHeaders,
            timeout
        }).catch(err => {
            this._logger && this._logger.error(err);
            this._errorHandler && this._errorHandler.onCatch(err);
            return err.cause;
        });
    }

    /**
     * @param url {Array|string}
     * @returns {*}
     */
    static normalizeUrl(url) {
        if (Array.isArray(url)) {
            return url.join('/');
        }

        return url.toString();
    }

}

class ApiError extends Error {
    static ERROR_NO_CONNECTION = 'NO_CONNECTION';

    errorCode;

    constructor(errorCode, ...params) {
        super(params);
        Error.captureStackTrace && Error.captureStackTrace(this, ApiError);

        this.errorCode = errorCode;
    }
}

Api.prototype.Error = ApiError;

export default Api;
