import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

import { lang } from "../../services";

import LoadingSpinner from "./LoadingSpinner";

const AppLoadingSpinner = (props) => (
    <LoadingSpinner
        className={classNames("AppLoadingSpinner", props.className)}
        classNameText={"AppLoadingSpinner__text"}
        isEnableSpinner={props.isEnableSpinner}
        showText={props.showText}
        text={lang.t(props.text)}
        fadeIn={props.fadeIn}
        name={props.name}
    />
);

AppLoadingSpinner.defaultProps = {
    isEnableSpinner: true,
    showText: false,
    text: "Reconnecting...",
    name: 'line-scale-pulse-out',
    className: '',
    fadeIn: LoadingSpinner.FADE_IN_FULL
};

AppLoadingSpinner.propTypes = {
    text: PropTypes.string,
    showText: PropTypes.bool,
    isEnableSpinner: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    fadeIn: PropTypes.string,
};

export default AppLoadingSpinner;