/**
 * Created by ebondarev
 */
import Types from '../../classes/types';
import { transport } from '../../services';
import { store } from '../../store';
import * as appActions from '../../actions/app';
import * as ordersActions from '../../actions/trade/orders';
import { AutoOrder } from '../../models/auto-order';
import { getCrossRate } from '../../utils/trade';
import { getSoundAction } from "../../utils/sound";

export const NOT_FETCHED = 'TRADE_POSITIONS_NOT_FETCHED';
export const FETCHING = 'TRADE_POSITIONS_FETCHING';
export const FETCHED = 'TRADE_POSITIONS_FETCHED';
export const ADD = 'TRADE_POSITIONS_ADD';
export const REMOVE = 'TRADE_POSITIONS_REMOVE';
export const UPDATE = 'TRADE_POSITIONS_UPDATE';
export const ACTIVE_POSITION_ON_CHART = 'ACTIVE_POSITION_ON_CHART';
export const UNSET_ACTIVE_POSITION = 'UNSET_ACTIVE_POSITION';
export const CHANGE_PNL_CURRENCY = 'TRADE_POSITIONS_CHANGE_PNL_CURRENCY';
export const CHANGE_SORT_ATTRIBUTE = 'TRADE_POSITIONS_CHANGE_SORT_ATTRIBUTE';
export const CHANGE_SORT_DIRECTION = 'TRADE_POSITIONS_CHANGE_SORT_DIRECTION';
export const TOGGLE_DETAILS_OF_POSITION = 'TRADE_POSITION_TOGGLE_DETAILS_OF_POSITION';
export const POSITIONS_FILTERS_UPDATED = 'POSITIONS_FILTERS_UPDATED';
export const POSITIONS_SEARCH = 'POSITIONS_SEARCH';

export function positionsFiltersUpdated() {
    return {
        type: POSITIONS_FILTERS_UPDATED,
    }
}

export function searchPositions(search = '') {
    return {
        type: POSITIONS_SEARCH,
        payload: search
    }
}

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function add(data) {
    const meta =  getSoundAction(Types.EVENT_SOUND_POSITION_OPENED);

    return {
        type: ADD,
        payload: data,
        meta,
    }
}

export function remove(data) {
    const keys = Object.keys(data);
    const positionsList = store.getState().trade.positions.list;
    const currency = positionsList.get(keys[0]).accountCurrency;
    const positions = keys.map(id => positionsList.get(id));
    const pnl = positions.reduce((pnl, p) => pnl + getCrossRate(p.accountCurrency, currency) * p.accountPnl, 0);
    const meta = getSoundAction((pnl > 0) ? Types.EVENT_SOUND_POSITION_CLOSED_PLUS : Types.EVENT_SOUND_POSITION_CLOSED_MINUS);

    return {
        type: REMOVE,
        payload: data,
        meta,
    }
}

export function update(data) {
    return {
        type: UPDATE,
        payload: data
    }
}

export function activePositionOnChart(orderNumber) {
    return {
        type: ACTIVE_POSITION_ON_CHART,
        payload: orderNumber,
    }
}

export function unsetActivePosition() {
    return {
        type: UNSET_ACTIVE_POSITION,
    }
}

export function changePnlCurrency(currency) {
    return {
        type: CHANGE_PNL_CURRENCY,
        payload: currency,
    }
}

export function sortAttribute(data) {
    return {
        type: CHANGE_SORT_ATTRIBUTE,
        payload: data
    }
}

export function sortDirection(data) {
    return {
        type: CHANGE_SORT_DIRECTION,
        payload: data
    }
}

export function toggleDetailsOfPosition(orderNumber) {
    return {
        type: TOGGLE_DETAILS_OF_POSITION,
        payload: orderNumber
    }
}

export function doActivePositionOnChart(orderNumber) {
    return (dispatch, getState) => {
        const { activeTab } = getState().trade.chart;
        const { activeOrderOnChart } = getState().trade.orders;
        const position = getState().trade.positions.list.get(orderNumber);

        if (!position || !activeTab) {
            return;
        }

        if (position.SymbolId === activeTab.symbolId) {
            if (activeOrderOnChart) {
                dispatch(ordersActions.unsetActiveOrder());
            }

            dispatch(activePositionOnChart(orderNumber));
        }
    }
}

export function doClosePosition({ positionId, amountInLots, accountNumber }) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;
        transport.requestClosePosition({
            positionId,
            amount: amountInLots,
            accountId: accountNumber || currentUser.AccountNumber
        });
    }
}

export function doTrailingStopSet({accountNumber, orderId, trailingStop}) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;
    
        const requestData = {
            accountNumber: accountNumber || currentUser.AccountNumber,
            orderId: orderId,
            trailingStop: trailingStop
        };
    
        transport.requestPositionTrailingStopSet(requestData);
    };
}

export function doTrailingStopRemove({accountNumber, orderId}) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;
    
        const requestData = {
            accountNumber: accountNumber || currentUser.AccountNumber,
            orderId: orderId
        };
    
        transport.requestPositionTrailingStopRemove(requestData);
    };
}

export function doCloseAllPositions({ symbolId, side, netting, accountNumber, waitNotification = false }) {
    return (dispatch, getState) => {
        // close all account positions
        if (accountNumber) {
            transport.requestCloseAllPositions({ symbolId, side, netting, accountNumber });
            return;
        }

        // close all positions
        const positions = getState().trade.positions.listToShow;
        let processedAccounts = {};
        let orderNumbers = [];

        positions.forEach(position => {
            if (waitNotification && (side ? position.sideText === side : true)) {
                orderNumbers.push(position.OrderNumber);
            }

            if (processedAccounts[position.AccountNumber]) {
                return;
            }

            processedAccounts[position.AccountNumber] = true;

            transport.requestCloseAllPositions({ accountNumber: position.AccountNumber, netting, symbolId, side });
        });

        waitNotification && dispatch(appActions.addWaitOrderNotification('waitRemove', { group: orderNumbers, netting, side }));
    }
}

/**
 * Close all positions of group
 * @param {*} positionsGroup 
 */
export function doClosePositionsGroup({ positionsGroup, side, netting = false, waitNotification = false }) {
    return (dispatch, getState) => {
        const positions = getState().trade.positions.listToShow;
        let processedAccounts = {};
        let orderNumbers = [];

        positionsGroup.orderNumbers.forEach(orderNumber => {
            const position = positions.get(orderNumber);

            if (!position || processedAccounts[position.AccountNumber]) {
                return;
            }

            if (waitNotification && (side ? position.sideText === side : true)) {
                orderNumbers.push(position.OrderNumber)
            }

            processedAccounts[position.AccountNumber] = true;

            transport.requestCloseAllPositions({
                symbolId: positionsGroup.symbolId,
                accountNumber: position.AccountNumber,
                netting,
                side
            })
        });

        waitNotification && dispatch(appActions.addWaitOrderNotification('waitRemove', { group: orderNumbers, netting, side }));
    }
}

export function doSetStopLoss({ accountNumber, orderId, price, slInputValue, slUnit, isPrice = true, changeAllUnit = false }) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;

        if (isNaN(price) || +price === 0) {
            transport.requestCancelPositionStopLoss({
                orderId,
                accountId: accountNumber || currentUser.AccountNumber
            });
        } else {
            transport.requestSetPositionStopLoss({
                orderId,
                price,
                accountId: accountNumber || currentUser.AccountNumber,
                isPrice,
                slInputValue,
                slUnit,
            });

            if (changeAllUnit) {
                dispatch(doChangeAllStopLossUnit({
                    skipOrderNumbers: [orderId],
                    slUnit,
                }));
            }
        }
    }
}

export function doSetTakeProfit({ accountNumber, orderId, price, tpInputValue, tpUnit, isPrice = true, changeAllUnit = false }) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;

        if (isNaN(price) || +price === 0) {
            transport.requestCancelPositionTakeProfit({
                orderId,
                accountId: accountNumber || currentUser.AccountNumber
            });
        } else {
            transport.requestSetPositionTakeProfit({
                orderId,
                price,
                accountId: accountNumber || currentUser.AccountNumber,
                isPrice,
                tpInputValue,
                tpUnit,
            });

            if (changeAllUnit) {
                dispatch(doChangeAllTakeProfitUnit({
                    skipOrderNumbers: [orderId],
                    tpUnit,
                }));
            }
        }
    }
}

export function doChangeAllStopLossUnit({ skipOrderNumbers = [], slUnit }) {
    return (dispatch, getState) => {
        if (!slUnit) {
            return;
        }

        const positions = getState().trade.positions.list
            .filter(p => !skipOrderNumbers.includes(p.OrderNumber) && p.SLOrderNumber && p.SLEnteredUnit !== slUnit);

        positions.forEach(position => {
            const account = getState().trade.accounts.list.get(position.AccountNumber);

            if (!account) {
                return;
            }

            const slValue = AutoOrder.positiveConvertValue({
                price: position.Price,
                autoCloseType: AutoOrder.TYPE.SL,
                Amount: position.UnitVolume,
                fromUnit: position.SLEnteredUnit,
                toUnit: slUnit,
                action: position.Side,
                leverage: position.leverage,
                crossRate: getCrossRate(position.Exp2, account.BaseCurrency),
            },
            position.SLEnteredVolume);

            dispatch(doSetStopLoss({
                accountNumber: position.AccountNumber,
                orderId: position.OrderNumber,
                price: position.SLPrice,
                slInputValue: slValue,
                slUnit,
                changeAllUnit: false,
            }));
        });
    }
}

export function doChangeAllTakeProfitUnit({ skipOrderNumbers = [], tpUnit }) {
    return (dispatch, getState) => {
        if (!tpUnit) {
            return;
        }

        const positions = getState().trade.positions.list
            .filter(p => !skipOrderNumbers.includes(p.OrderNumber) && p.TPOrderNumber && p.TPEnteredUnit !== tpUnit);

        positions.forEach(position => {
            const account = getState().trade.accounts.list.get(position.AccountNumber);

            if (!account) {
                return;
            }

            const tpValue = AutoOrder.positiveConvertValue({
                price: position.Price,
                autoCloseType: AutoOrder.TYPE.TP,
                Amount: position.UnitVolume,
                fromUnit: position.TPEnteredUnit,
                toUnit: tpUnit,
                action: position.Side,
                leverage: position.leverage,
                crossRate: getCrossRate(position.Exp2, account.BaseCurrency),
            },
            position.TPEnteredVolume);

            dispatch(doSetTakeProfit({
                accountNumber: position.AccountNumber,
                orderId: position.OrderNumber,
                price: position.TPPrice,
                tpInputValue: tpValue,
                tpUnit,
                changeAllUnit: false,
            }));
        });
    }
}