/**
 * Created by ebondarev
 */
import { Record, Map, List } from 'immutable';

import { store } from '../../store';
import { sort } from '../utils';
import * as actionTypes from '../../actions/trade/tickers';
import * as appTypes from '../../actions/app';
import * as userTypes from '../../actions/user/user';
import * as accountsTypes from '../../actions/trade/accounts';
import * as trackingTypes from '../../actions/tracking/tracking';
import * as transportTradeTypes from '../../actions/transports/trade';
import { TickerModel } from '../../models/ticker';
import Register from '../../classes/register';
import Types from '../../classes/types';
import AppConfig from '../../AppConfig';

const REGISTER_DISABLE_LIST = 'tickers_disable';
const STORAGE_TICKERS_MARKS = 'tickers_marks';
const { cryptoListJson, disableInstrumentsFilter } = AppConfig;
const {sortCryptocurrenciesByPositionJson} = AppConfig.features;

class TickersStore extends Record({
    list: new Map(),
    listToShow: new Map(), // из-за частых событий UPDATE_QUOTE лучше держать состояние тут
    listWoQuotes: new Map(), // full list without update by quote
    disableList: new Map(disableListToMap(Register.get(REGISTER_DISABLE_LIST, []))),
    search: '',
    lpNameFilter: '',
    tickerGroupFilter: 0,
    status: actionTypes.NOT_FETCHED,
    isReady: false, // были получены тикеры, статус может быть FETCHING (обновление)
    lastUpdateAt: Date.now(),
    lastUpdateListToShowAt: Date.now(),
    detailSortAttribute: "",
    detailSortDirection: Types.SORT_ASC,
    detailSearchTicker: "",
    tradeSymbolId: null, // current ticker's SymbolId in mobile trade component
    tradeSide: null, // order's side in mobile trade component

    // Instruments
    tickersSearch: '',
    tickersSortAttribute: 'Ticker',
    tickersSortDirection: Types.SORT_ASC,

    _crossTable: {},
    _crossInstruments: {},
    tickersMarks: new List(Register.get(STORAGE_TICKERS_MARKS, []))
}) {
    isFetched() {
        return this.status === actionTypes.FETCHED
    }

    availableForCurrentUser() {
        const lpNameFilter = this.lpNameFilter.toLowerCase();

        return this.list.filter((ticker) => {
            return ticker.LPName.toLowerCase().indexOf(lpNameFilter) >= 0;
        }).toArray();
    }

    notAvailableForCurrentUser() {
        const lpNameFilter = this.lpNameFilter.toLowerCase();

        return this.list.filter((ticker) => {
            return ticker.LPName.toLowerCase().indexOf(lpNameFilter) === -1;
        }).toArray();
    }

    _calcCrossRate(currencyFrom, symbolIds, dayChange = false) {
        let rate = 1,
            from = currencyFrom;

        for (let i = 0; i < symbolIds.length; i++) {
            const ticker = this.list.get(symbolIds[i]);

            if (!ticker || ticker.Bid === 0 || ticker.Ask === 0) {
                return 1;
            }

            const bid = dayChange ? ticker.LastMTBid : ticker.Bid;
            const ask = dayChange ? ticker.LastMTAsk : ticker.Ask;

            if (dayChange && (!bid || !ask)) {
                return 0;
            }

            rate = rate * (ticker.Exp1 === from ? bid : 1 / ask);
            from = ticker.Exp1 === from ? ticker.Exp2 : ticker.Exp1;
        }

        return rate;
    }

    _findCrossSequence(from, to, crossTable) {
        let queue = [],
            parents = {},
            used = {},
            seq = [],
            symbol;

        queue.push(from);
        parents[from] = false;
        used[from] = true;

        while (queue.length) {
            symbol = queue.shift();

            if (crossTable[symbol] && crossTable[symbol][to]) {
                parents[to] = symbol;
                for (let i = to; parents[i]; i = parents[i]) {
                    seq.push(crossTable[parents[i]][i]);
                }
                return seq.reverse();
            }

            for (let key in crossTable[symbol]) {
                if (!used[key]) {
                    queue.push(key);
                    parents[key] = symbol;
                    used[key] = true;
                }
            }
        }

        return seq;
    }

    getCrossRate(currencyFrom, currencyTo, dayChange = false) {
        let symbolIds = [];

        if (currencyFrom === currencyTo) {
            return 1;
        }

        // from cache
        const ID = currencyFrom + currencyTo;
        if (this._crossInstruments[ID]) {
            return this._calcCrossRate(currencyFrom, this._crossInstruments[ID], dayChange);
        }

        if (this._crossTable[currencyFrom] && this._crossTable[currencyFrom][currencyTo]) {
            // direct
            symbolIds = [this._crossTable[currencyFrom][currencyTo]];
        } else {
            // find cross sequence
            symbolIds = this._findCrossSequence(currencyFrom, currencyTo, this._crossTable);
        }

        // cache result
        this._crossInstruments[ID] = symbolIds;

        return this._calcCrossRate(currencyFrom, symbolIds, dayChange);
    }

    /**
     * Return instrument's leverage if found or DefaultInstrumetLeverage from config
     * @param {number} symbolId instrument id
     * @param {bool} forTrade leverage for trade instead instrument leverage
     * @returns {number} instrument's leverage
     */
    getLeverage(symbolId, forTrade = false) {
        const marginModels = store.getState().trade.marginModels.list;
        const currentAccount = store.getState().trade.accounts.current;
        const ticker = this.list.get(symbolId);
        const defaultLeverage = AppConfig.DefaultInstrumetLeverage;

        if (ticker) {
            const marginModel = marginModels.get(ticker.MarginModelId);

            if (!marginModel) {
                return defaultLeverage;
            }

            if (!forTrade || ticker.isExchange) {
                return marginModel.InitMargin;
            }

            if (forTrade && currentAccount) {
                return marginModel.InitMargin > currentAccount.InitLeverage ?
                    marginModel.InitMargin : currentAccount.InitLeverage;
            }
        }

        return defaultLeverage;
    }

    /**
     * Get list of exchange instruments currencies
     * @returns {array}
     */
    getExchangeCurrencies() {
        let currencyMap = {};

        this.list.filter(ticker => ticker.TradeMode === Types.INSTRUMENT_TRADE_MODE_EXCHANGE)
        .forEach(ticker => {
            currencyMap[ticker.Exp1] = true;
            currencyMap[ticker.Exp2] = true;
        });

        return Object.keys(currencyMap);
    }

}

function convertDataToTickersMap(data) {
    let tickerGroup = store.getState().trade.tickersGroups.list.find((t) => { return t.Name === "Cryptocurrencies" });
    let cryptoList = cryptoListJson;

    if(!tickerGroup) {
        for(const key in data) {
            if(data[key].InstrumentTypeName === "Cryptocurrencies" || data[key].InstrumentTypeName === "Crypto") {
                data[key].Id = data[key].InstrumentTypeId;
                tickerGroup = data[key];
                break;
            }
        }
    }

    if(tickerGroup && sortCryptocurrenciesByPositionJson && cryptoList && cryptoList.length > 0 ) {

        let iIndex = 100;
        let jIndex = 0.001;

        for(const key in data) {
            if(data[key].InstrumentTypeId === tickerGroup.Id){
                const exTicker = cryptoList.find((t) => { return t.Exp1 === data[key].Exp1 });
                if(exTicker) {
                    jIndex = jIndex + 0.001;
                    const count = exTicker.sortId + jIndex;
                    data[key].sortId = parseFloat(count.toFixed(3));
                } else {
                    iIndex++;
                    data[key].sortId = parseFloat(iIndex);
                }
            } else {
                data[key].sortId = "";
            }
        }

        return sort(new Map(data), 'sortId').map((tickerData) => {
            return new TickerModel(tickerData);
        });
    } else {
        return sort(new Map(data), 'Ticker').map((tickerData) => {
            return new TickerModel(tickerData);
        })
    }
}

function listToShow(list, lpNameFilter, tickersSearch, tickerGroupFilter = 0) {
    const lpName = lpNameFilter.toLowerCase();
    const search = tickersSearch.trim().toLowerCase();
    
    const cleanSearch = search.replace(/[^a-zA-Z0-9]/g, '');

    if (!search) {
        return list.filter(ticker => !tickerGroupFilter || ticker.InstrumentTypeId === tickerGroupFilter);
    }

    return list.filter(ticker => {
        const name = ticker.Name.toLowerCase();
        const instrumentTicker = ticker.Ticker.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        return ticker.visible && ticker.LPName.toLowerCase().indexOf(lpName) >= 0 && ((search && name.includes(search))
            || (cleanSearch && name.includes(cleanSearch))
            || (search && instrumentTicker.includes(search))
            || (cleanSearch && instrumentTicker.includes(cleanSearch))) && (!tickerGroupFilter || ticker.InstrumentTypeId === tickerGroupFilter);
    });
}

function disableListToMap(list) {
    const disableList = {};
    if (!Array.isArray(list)) {
        return disableList;
    }

    list.forEach((symbolId) => {
        disableList[symbolId] = symbolId;
    });

    return disableList;
}

function updateQuote(list, quote) {
    if (list.has(quote.SymbolId)) {
        return list.update(quote.SymbolId, (ticker) => {
            if (ticker.Ask === quote.Ask && ticker.Bid === quote.Bid) {
                return ticker.updateFromQuote(quote);
            }

            return ticker.updateFromQuote(quote).clone(); // make new object for re-render watched components
        });
    }

    return list;
}

function updateMark(list, symbolId) {
    if (list.has(symbolId)) {
        return list.update(symbolId, ticker => {
            ticker.isMark = !ticker.isMark;
            return ticker.clone();
        });
    }

    return list;
}

function listToCrossTable(list) {
    let crossTable = {};

    list.forEach((ticker) => {
        if (!crossTable[ticker.Exp1]) {
            crossTable[ticker.Exp1] = {};
        }

        if (!crossTable[ticker.Exp2]) {
            crossTable[ticker.Exp2] = {};
        }

        if (!crossTable[ticker.Exp1][ticker.Exp2]) {
            crossTable[ticker.Exp1][ticker.Exp2] = ticker.SymbolId;
            crossTable[ticker.Exp2][ticker.Exp1] = ticker.SymbolId;
        }
    });

    return crossTable;
}

function saveTickersMarks(tickersMarks) {
    window.setTimeout(() => {
        Register.set(STORAGE_TICKERS_MARKS, tickersMarks);
    });
}

const initialState = new TickersStore();

export default function tickers(state = initialState, action) {
    let list = state.list;

    switch (action.type) {
        case actionTypes.UPDATE_QUOTE:
            list = updateQuote(state.list, action.payload);

            return state.concat({
                list,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.UPDATE_QUOTES:
        case transportTradeTypes.UPDATE_DATA:
            const updatedData = action.type === transportTradeTypes.UPDATE_DATA
                ? action.payload.quotes : action.payload;

            if (!updatedData) {
                return state;
            }

            for (let symbolId in updatedData) {
                list = updateQuote(list, updatedData[symbolId]);
            }

            return state.merge({
                list,
                listToShow: list === state.list ? state.listToShow : listToShow(list, state.lpNameFilter, state.search, state.tickerGroupFilter),
                lastUpdateAt: Date.now(),
            });

        case actionTypes.FETCHED:
            const listFetched = convertDataToTickersMap(action.payload);
            listFetched.forEach((ticker) => {
                ticker.visible = !state.disableList.has(ticker.SymbolId);
                ticker.isMark = state.tickersMarks.indexOf(ticker.SymbolId) !== -1;
            });

            state.concat({
                list: listFetched,
                listToShow: listToShow(listFetched, state.lpNameFilter, state.search, state.tickerGroupFilter),
                listWoQuotes: listFetched,
                _crossTable: listToCrossTable(listFetched),
                _crossInstruments: {},
            });

            return state.merge({
                isReady: true,
                status: actionTypes.FETCHED,
                lastUpdateAt: Date.now(),
            });

        case actionTypes.FETCHING:
            return state.merge({
                status: actionTypes.FETCHING
            });

        case actionTypes.UPDATE_SEARCH:
            const updatedSearch = action.payload ? action.payload.toString() : '';
            return state.merge({
                search: updatedSearch,
                listToShow: listToShow(state.list, state.lpNameFilter, updatedSearch, state.tickerGroupFilter)
            });

        case actionTypes.UPDATE_TICKER_GROUP_FILTER:
            const  newTickerGroupFilter = action.payload ? +action.payload : 0;
            return state.merge({
                tickerGroupFilter: newTickerGroupFilter,
                listToShow: listToShow(state.list, state.lpNameFilter, state.search, newTickerGroupFilter)
            });

        case actionTypes.UPDATE_DISABLED_LIST:
            const disableList = disableListToMap(action.payload);
            state.list.forEach((ticker) => {
                ticker.visible = !disableList[ticker.SymbolId];
            });

            Register.set(REGISTER_DISABLE_LIST, Object.keys(disableList));

            return state.merge({
                disableList: new Map(disableList),
                listToShow: listToShow(state.list, state.lpNameFilter, state.search, state.tickerGroupFilter)
            });

        case actionTypes.CHANGE_DETAIL_SORT_ATTRIBUTE:
            return state.merge({
                detailSortAttribute: action.payload
            });

        case actionTypes.CHANGE_DETAIL_SORT_DIRECTION:
            return state.merge({
                detailSortDirection: action.payload
            });

        case actionTypes.CHANGE_DETAIL_SEARCH_TICKER:
            return state.merge({
                detailSearchTicker: action.payload
            });

        case actionTypes.CHANGE_TICKERS_SORT_ATTRIBUTE:
            return state.merge({
                tickersSortAttribute: action.payload
            });

        case actionTypes.CHANGE_TICKERS_SORT_DIRECTION:
            return state.merge({
                tickersSortDirection: action.payload
            });

        case actionTypes.CHANGE_TICKERS_SEARCH:
            return state.merge({
                tickersSearch: action.payload
            });

        case appTypes.SHOW_ASSETS_MODAL:
        case appTypes.SHOW_FAVORITES_MODAL:
                return state.merge({
                    tickersSearch: ""
                });

        case accountsTypes.CHANGED_CURRENT_ACCOUNT:
            let updatedLpNameFilter = disableInstrumentsFilter ? '' : action.payload ? action.payload.DealerName : '';

            return state.merge({
                lpNameFilter: updatedLpNameFilter,
                tickerGroupFilter: 0,
                listToShow: listToShow(state.list, updatedLpNameFilter, state.search)
            });

        case actionTypes.CHAGNE_TRADE_TICKER:
            return state.merge({
                tradeSymbolId: action.payload.symbolId,
                tradeSide: action.payload.side || state.tradeSide,
            });

        case actionTypes.CHAGNE_TRADE_SIDE:
            return state.merge({
                tradeSide: action.payload,
            });

        case userTypes.LOG_OUT:
            return initialState.merge({
                list: new Map(),
                listToShow: new Map(),
                tickersMarks: new List(Register.get(STORAGE_TICKERS_MARKS, []))
            });

        case actionTypes.MARK_TICKER:
            let tickersMarks = state.tickersMarks.toArray();
            let tickerIndex = tickersMarks.indexOf(action.payload);

            if (tickerIndex === -1) {
                tickersMarks.push(action.payload);
            } else {
                tickersMarks.splice(tickerIndex, 1);
            }

            saveTickersMarks(tickersMarks);

            list = updateMark(list, action.payload);

            return state.merge({
                list,
                listToShow: list === state.list ? state.listToShow : listToShow(list, state.lpNameFilter, state.search),
                listWoQuotes: list,
                lastUpdateAt: Date.now(),
                tickersMarks: new List(tickersMarks),
            });

            case trackingTypes.GET_STORE:
                if (action.payload) {
                    const tickerStore = state;
                    action.data.tickers = {
                        search: tickerStore.search,
                        lpNameFilter: tickerStore.lpNameFilter,
                        tickerGroupFilter: tickerStore.tickerGroupFilter,
                        detailSortAttribute: tickerStore.detailSortAttribute,
                        detailSortDirection: tickerStore.detailSortDirection,
                        detailSearchTicker: tickerStore.detailSearchTicker,
                        tradeSymbolId: tickerStore.tradeSymbolId, // current ticker's SymbolId in mobile trade component
                        tradeSide: tickerStore.tradeSide, // order's side in mobile trade component
                        tickersMarks: tickerStore.tickersMarks,

                        tickersSearch: tickerStore.tickersSearch,
                        tickersSortAttribute: tickerStore.tickersSortAttribute,
                        tickersSortDirection: tickerStore.tickersSortDirection,
                    }
                    return state;
                }
                return initialState;
    
            case trackingTypes.SET_STORE:
                if (action.payload && action.payload.tickers) {
                    return state.merge(action.payload.tickers);
                }
                return state;

        default:
            return state;
    }
}
