/**
 * Created by ebondarev
 */
import Types from '../../classes/types';
import { transport } from '../../services';
import { store } from '../../store';
import { setWaitingResult } from '../../actions/app';
import * as positionsActions from '../../actions/trade/positions';
import * as appActions from '../../actions/app';
import { AutoOrder } from '../../models/auto-order';
import { getCrossRate } from '../../utils/trade';
import { getSoundAction } from "../../utils/sound";

export const NOT_FETCHED = 'TRADE_ORDERS_NOT_FETCHED';
export const FETCHING = 'TRADE_ORDERS_FETCHING';
export const FETCHED = 'TRADE_ORDERS_FETCHED';
export const ADD = 'TRADE_ORDERS_ADD';
export const REMOVE = 'TRADE_ORDERS_REMOVE';
export const UPDATE = 'TRADE_ORDERS_UPDATE';
export const ADD_REPLACE_ORDER = 'TRADE_ORDERS_ADD_REPLACE_ORDER';
export const REMOVE_REPLACE_ORDER = 'TRADE_ORDERS_REMOVE_REPLACE_ORDER';
export const ACTIVE_ORDER_ON_CHART = 'ACTIVE_ORDER_ON_CHART';
export const UNSET_ACTIVE_ORDER = 'UNSET_ACTIVE_ORDER';
export const CHANGE_SORT_ATTRIBUTE = 'TRADE_ORDERS_CHANGE_SORT_ATTRIBUTE';
export const CHANGE_SORT_DIRECTION = 'TRADE_ORDERS_CHANGE_SORT_DIRECTION';
export const TOGGLE_DETAILS_OF_ORDER = 'TRADE_ORDERS_TOGGLE_DETAILS_OF_ORDER';
export const ORDERS_FILTERS_UPDATED = 'ORDERS_FILTERS_UPDATED';
export const ORDERS_SEARCH = 'ORDERS_SEARCH';

export function ordersFiltersUpdated() {
    return {
        type: ORDERS_FILTERS_UPDATED,
    }
}

export function searchOrders(search = '') {
    return {
        type: ORDERS_SEARCH,
        payload: search
    }
}

export function fetching() {
    return {
        type: FETCHING,
    }
}

export function fetched(data) {
    return {
        type: FETCHED,
        payload: data
    }
}

export function add(data) {
    let playSound = false;
    let action = {
        type: ADD,
        payload: data
    };

    // play sound for pending orders
    if (typeof(data) === 'object') {
        for (let key in data) {
            if (data[key].OrderType === Types.ORDER_TYPE_LIMIT
                || data[key].OrderType === Types.ORDER_TYPE_STOP)
            {
                playSound = true;
            }
        }
    }

    if (playSound && store.getState().user.settings.enableSounds) {
        action['meta'] = getSoundAction(Types.EVENT_SOUND_ORDER_OPENED);
    }

    return action;
}

export function remove(data) {
    let playSound = false;
    let action = {
        type: REMOVE,
        payload: data
    };

    if (typeof(data) === 'object') {
        for (let key in data) {
            if (data[key].OrderType === Types.ORDER_TYPE_LIMIT
                || data[key].OrderType === Types.ORDER_TYPE_STOP)
            {
                playSound = true;
            }
        }
    }

    if (playSound && store.getState().user.settings.enableSounds) {
        action['meta'] = getSoundAction(Types.EVENT_SOUND_POSITION_CLOSED);
    }

    return action
}

export function update(data) {
    return {
        type: UPDATE,
        payload: data
    }
}

export function addReplaceOrder(data) {
    return {
        type: ADD_REPLACE_ORDER,
        payload: data
    }
}

export function removeReplaceOrder(orderId) {
    return {
        type: REMOVE_REPLACE_ORDER,
        payload: orderId
    }
}

export function activeOrderOnChart(orderNumber) {
    return {
        type: ACTIVE_ORDER_ON_CHART,
        payload: orderNumber
    }
}

export function unsetActiveOrder() {
    return {
        type: UNSET_ACTIVE_ORDER,
    }
}

export function sortAttribute(data) {
    return {
        type: CHANGE_SORT_ATTRIBUTE,
        payload: data
    }
}

export function sortDirection(data) {
    return {
        type: CHANGE_SORT_DIRECTION,
        payload: data
    }
}

export function toggleDetailsOfOrder(orderNumber) {
    return {
        type: TOGGLE_DETAILS_OF_ORDER,
        payload: orderNumber
    }
}

export function doRemoveOrders(data) {
    return (dispatch, getState) => {
        dispatch(remove(data));

        const replaceOrderList = getState().trade.orders.replaceOrderList;

        Object.keys(data).forEach(orderId => {
            if (replaceOrderList.has(orderId)) {
                dispatch(doCreateOrder(replaceOrderList.get(orderId)));
                dispatch(removeReplaceOrder(orderId));
            }
        });
    }
}

export function doActiveOrderOnChart(orderNumber) {
    return (dispatch, getState) => {
        const { activeTab } = getState().trade.chart;
        const { activePositionOnChart } = getState().trade.positions;
        const order = getState().trade.orders.list.get(orderNumber);

        if (!order || !activeTab) {
            return;
        }

        if (order.SymbolId === activeTab.symbolId) {
            if (activePositionOnChart) {
                dispatch(positionsActions.unsetActivePosition());
            }

            dispatch(activeOrderOnChart(orderNumber));
        }
    }
}

export function doCancelOrder({ orderId, accountNumber }) {
    return (dispatch, getState) => {
        const accountId = accountNumber || getState().trade.accounts.current.AccountNumber;
        transport.requestCancelOrder({ orderId, accountId });
    }
}

export function doCancelAllOrders({ symbolId, side, accountNumber = "", useCurrentAccount = false, waitNotification = false }) {
    return (dispatch, getState) => {
        const accountId = useCurrentAccount ? getState().trade.accounts.current.AccountNumber : accountNumber;

        // cancel all account orders
        if (accountId) {
            transport.requestCancelAllOrders({ accountId, symbolId, side });
            return;
        }

        // cancel all orders
        const orders = getState().trade.orders.listToShow;
        let processedAccounts = {};
        let orderNumbers = [];

        orders.forEach(order => {
            if (waitNotification && (side ? order.sideText === side : true)) {
                orderNumbers.push(order.OrderNumber);
            }

            if (processedAccounts[order.AccountNumber]) {
                return;
            }

            processedAccounts[order.AccountNumber] = true;

            transport.requestCancelAllOrders({ accountId: order.AccountNumber, symbolId, side });
        });

        waitNotification && dispatch(appActions.addWaitOrderNotification('waitRemove', { group: orderNumbers, side }));
    }
}

/**
 * Cancel all orders of group
 * @param {*} ordersGroup 
 */
export function doCancelOrdersGroup({ ordersGroup, side, waitNotification = false }) {
    return (dispatch, getState) => {
        const orders = getState().trade.orders.listToShow;
        let processedAccounts = {};
        let orderNumbers = [];

        ordersGroup.orderNumbers.forEach(orderNumber => {
            const order = orders.get(orderNumber);

            if (waitNotification && (side ? order.sideText === side : true)) {
                orderNumbers.push(order.OrderNumber)
            }

            if (!order || processedAccounts[order.AccountNumber]) {
                return;
            }

            processedAccounts[order.AccountNumber] = true;

            transport.requestCancelAllOrders({
                accountId: order.AccountNumber,
                symbolId: ordersGroup.symbolId,
                side
            });
        });

        waitNotification && dispatch(appActions.addWaitOrderNotification('waitRemove', { group: orderNumbers, side }));
    }
}

export function doReplaceOrder({ orderId, newOrder }) {
    return (dispatch, getState) => {
        dispatch(addReplaceOrder({ orderId, newOrder }));

        return dispatch(doCancelOrder({ orderId }));
    }
}

/**
 * @param model{OrderFormModel}
 */
export function doCreateOrder(model) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;
        let trailingStop = model.trailingStop;

        if (model.trailingStop === 1) {
            trailingStop = model.getSLinPips(model.stopLoss, model.takeProfit);
        }

        if (model.trailingStop === "NaN") {
            trailingStop = 0;
        }
        
        dispatch(setWaitingResult(true));

        const price = (model.type === Types.ORDER_TYPE_MARKET)
            ? undefined
            : model.priceToOrder;
        let isPrice,
        takeProfit = model.takeProfitToOrder,
        stopLoss = model.stopLossToOrder;

        if (model.type === Types.ORDER_TYPE_MARKET ) {
            if (model.takeProfitEnteredUnit && model.takeProfitEnteredUnit !== Types.SLTP_UNIT_PRICE) {
                takeProfit = model.tpOffsetPoints;
                isPrice = false;
            }

            if (model.stopLossEnteredUnit && model.stopLossEnteredUnit !== Types.SLTP_UNIT_PRICE) {
                stopLoss = model.slOffsetPoints;
                isPrice = false;
            }
        }
        
        let requestData = {
            accountNumber: model.accountNumber || currentUser.AccountNumber,
            receiveAccountNumber: model.receiveAccountNumber,
            type: Types.orderType(model.type),
            symbolId: model.symbolId,
            buySell: Types.side(model.side),
            side: model.side, // @deprecated
            price: price,
            size: model.sizeInLots,
            stopLoss: stopLoss,
            takeProfit: takeProfit,
            isPrice: isPrice,
            trailingStop: Math.abs(trailingStop),
            timeInForce: model.timeInForce,
            tradeMode: model.tradeMode,
            TPEnteredVolume: model.TPEnteredVolume || model.takeProfitEnteredVolume,
            TPEnteredUnit: model.TPEnteredUnit || model.takeProfitEnteredUnit,
            SLEnteredVolume: model.SLEnteredVolume || model.stopLossEnteredVolume,
            SLEnteredUnit: model.SLEnteredUnit || model.stopLossEnteredUnit,
        };

        transport.requestCreateOrder(requestData);
    }
}

export function doTrailingStopSet({accountNumber, orderId, trailingStop}) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;
    
        const requestData = {
            accountNumber: accountNumber || currentUser.AccountNumber,
            orderId: orderId,
            trailingStop: trailingStop
        };
    
        transport.requestOrderTrailingStopSet(requestData);
    };
}

export function doTrailingStopRemove({accountNumber, orderId}) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;
    
        const requestData = {
            accountNumber: accountNumber || currentUser.AccountNumber,
            orderId: orderId
        };
    
        transport.requestOrderTrailingStopRemove(requestData);
    };
}

export function doModifyOrder({ accountNumber, orderId, type, price, size, stopLoss, takeProfit, tpInputValue, tpUnit, slInputValue, slUnit, boundTo = 0 }) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;

        const requestData = {
            accountNumber: accountNumber || currentUser.AccountNumber,
            orderId: orderId,
            type: type,
            price: price,
            size: size,
            stopLoss: stopLoss,
            takeProfit: takeProfit,
            TPEnteredVolume: tpInputValue,
            TPEnteredUnit: tpUnit,
            SLEnteredVolume: slInputValue,
            SLEnteredUnit: slUnit,
            boundTo
        };

        transport.requestReplaceOrder(requestData);
    }
}

export function doModifyOrderSLTP({ accountNumber, orderId, stopLoss, takeProfit, tpInputValue, tpUnit, slInputValue, slUnit, changeAllUnit = false }) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;

        transport.requestSetOrderSLTP({
            orderId,
            accountId: accountNumber || currentUser.AccountNumber,
            stopLoss,
            takeProfit,
            tpInputValue,
            tpUnit,
            slInputValue,
            slUnit,
        });

        if (changeAllUnit) {
            dispatch(doChangeAllSLTPUnit({
                skipOrderNumbers: [orderId],
                unit: tpUnit || slUnit,
            }));
        }
    }
}

export function doChangeAllSLTPUnit({ skipOrderNumbers = [], unit }) {
    return (dispatch, getState) => {
        if (!unit) {
            return;
        }

        const orders = getState().trade.orders.list.filter(o => {
            return !skipOrderNumbers.includes(o.OrderNumber)
                && (o.TPOrderNumber || o.SLOrderNumber)
                && (o.TPEnteredUnit !== unit || o.SLEnteredUnit !== unit)
        });

        orders.forEach(order => {
            const account = getState().trade.accounts.list.get(order.AccountNumber);
            const changeSL = order.SLEnteredUnit && order.SLEnteredUnit !== unit;
            const changeTP = order.TPEnteredUnit && order.TPEnteredUnit !== unit

            if (!account) {
                return;
            }

            const slValue = changeSL ? AutoOrder.positiveConvertValue({
                price: order.Price,
                autoCloseType: AutoOrder.TYPE.SL,
                Amount: order.UnitVolume,
                fromUnit: order.SLEnteredUnit,
                toUnit: unit,
                action: order.Side,
                leverage: order.leverage,
                crossRate: getCrossRate(order.Exp2, account.BaseCurrency),
            },
            order.SLEnteredVolume) : order.SLEnteredVolume;

            const tpValue = changeTP ? AutoOrder.positiveConvertValue({
                price: order.Price,
                autoCloseType: AutoOrder.TYPE.TP,
                Amount: order.UnitVolume,
                fromUnit: order.TPEnteredUnit,
                toUnit: unit,
                action: order.Side,
                leverage: order.leverage,
                crossRate: getCrossRate(order.Exp2, account.BaseCurrency),
            },
            order.TPEnteredVolume) : order.TPEnteredVolume;

            if (changeSL || changeTP) {
                dispatch(doModifyOrderSLTP({
                    orderId: order.OrderNumber,
                    accountId: order.AccountNumber,
                    stopLoss: order.SLPrice,
                    takeProfit: order.TPPrice,
                    tpInputValue: changeTP ? tpValue : order.TPEnteredVolume,
                    tpUnit: changeTP ? unit : order.TPEnteredUnit,
                    slInputValue: changeSL ? slValue : order.SLEnteredVolume,
                    slUnit: changeSL ? unit : order.SLEnteredUnit,
                }));
            }
        });
    }
}

export function doCancelOrderStopLoss(orderId) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;

        transport.requestCancelOrderStopLoss({
            orderId,
            accountId: currentUser.AccountNumber
        });
    }
}

export function doCancelOrderTakeProfit(orderId) {
    return (dispatch, getState) => {
        const currentUser = getState().trade.accounts.current;

        transport.requestCancelOrderTakeProfit({
            orderId,
            accountId: currentUser.AccountNumber
        });
    }
}