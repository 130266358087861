/**
 * Created by ebondarev
 */
import { Record, Map, Stack, List, Set } from 'immutable';
import * as _ from 'lodash';

import AppConfig from '../AppConfig';
import Types from '../classes/types';
import { lang } from '../services';
import Register from '../classes/register';
import { store } from '../store';
import Message from '../common/components/Message';

import * as actionTypes from '../actions/app'
import * as actionPositionTypes from '../actions/trade/positions';
import * as actionOrderTypes from '../actions/trade/orders';
import * as trackingActions from '../actions/tracking/tracking'
import * as messageBoxActions from '../actions/message-box'
import { handledActions } from './tracking/app'
import * as actionUserInfoTypes from '../actions/user/info';
import * as authTypes from '../actions/user/auth';
import { changeTradeMode, stopAnimatedComponentsAnimation } from "../actions/app";

const STORAGE_TRADE_MODE = 'trade_mode';
const ANIMATED_COMPONENTS_ANIMATION_SPEED = 500;

const waitOrderNotificationsRecord = Record({
    waitUpdate: new Map(),
    waitRemove: new Map(),
});

const initialState = new Record({
    status: actionTypes.NOT_INITIALIZED,
    lang: AppConfig.defines.APP_LANG,
    isLanguageChanging: false,
    tradeMode: getTradeMode(),
    animatedComponentsHiding: false,
    animatedComponentsChanging: false,
    activeTab: null,
    showMarginCallModals: Stack(),
    isShowMarginCallModal: false,
    showStopOutModals: List(),
    isShowStopOutModal: false,
    isSidePanelOpen: false,
    sidePanelElement: '',
    sidePanelComponent: null,
    isSuccessfulTrade: false,
    isShowGlobalSettings: false,
    globalSettingsGroup: 'general',
    isShowInstrumentDetails: false,
    isShowInstrumentList: false,
    isShowActivityLogModal: false,
    isShowOnBoardingModal: false,
    isShowAppropriatenessTestModal: false,
    isShowMarginHistoryModal: false,
    isShowWithdrawModal: false,
    isShowChangePasswordModal: false,
    isShowInterchangeModal: false,
    isShowTwoFAModal: false,
    isShowEmailTwoFAModal: false,
    isShowSecurityModal: false,
    securityModalGroup: Types.SECURITY_WINDOW.CHANGE_PASSWORD,
    forceInitTwoFA: false,
    isShowCreateAccountModal: false,
    isShowDepositModal: false,
    isShowDepositBXCModal: false,
    isShowDepositDemoAccount: false,
    isShowTransactionHistoryModal: false,
    isShowExchangeHistoryModal: false,
    isShowMtMessage: false,
    isShowKycFinishNotification: false,
    isShowCallBackForm: false,
    isShowWalletsModal: false,
    isShowProfileModal: false,
    isShowPlatformNotificationsModal: false,
    isShowHistoryModal: false,
    isShowConsolidatedReportModal: false,
    isShowPositionsModal: false,
    editPositionNumber: '',
    partialClosePositionNumber: '',
    isShowOrdersModal: false,
    isShowSumsubKycModal: false,
    isShowUserMenu: false,
    isClientWrongTimeShown: false,
    isDisabledPasswordChangeModalClose: false,
    activeInvestingTab: 0,
    activeInvestingId: null,
    isShowChartSettingsDialog:false,
    isShowAccountBlockMessage: false,
    isShowTradeOnDemoModal: false,
    isShowScreenSharingRequestMessage: false,
    isShowScreenSharingEndMessage: false,
    isShowInsufficientFundsMessage: false,
    isShowAutoLogOutMessage: false,
    isRunningTimeOutTimer: false,
    isTrackingActive: true,
    trackingState: null,
    mtBeginTime: null,
    mtEndTime: null,
    redirectUrl: null,
    isShowTabsMenu: true, // only mobile
    isActivePanelMenu: true, // right (addition) menu
    isActiveMobilePanelMenu: false, // mobile left panel
    headerTitle: null, // only mobile
    page: null, // only mobile
    panelMenuActions: null, // only mobile
    isChartControlElementHover: false,
    instrumentListLeftPosition: 0,
    instrumentListRightPosition: 0,
    fullScreenDivId: null,
    ServerTime: Date.now(),
    lastServerTime: Date.now(),
    serverTimeOffset: 0, // seconds
    waitOrderNotifications: new waitOrderNotificationsRecord(),
    confirmationMessages: new Map(),
    notificationMessage: new Record({
        message: null,
        isNotificationMessageShow:false,
        type: Message.TYPE_INFORMATION,
        callback: null,
        description: null
    })(),
    userNotification: new Record({
        text: null,
        updatedAt: 0,
    })(), // only mobile
    isWaitingResult: false,
    mobileCurrentPanelTab: "/",
    isShowAccountMenuModal: false,
    isShowSettingsMenuModal: false,
    isShowIndicatorsModal: false,
    isShowDrawObjectsModal: false,
    isShowAccessKeysModal: false,
    blockOutsideClickClose: false,
    blockNavigationMenu: false,
    advancedSearchRequests: new Map(),
    colorTheme: "",
    isShowEconomicCalendarModal: false,
    isShowNewsListModal: false,
    isShowInvestingModal: false,
    isShowPendingOrderModal: false,
    pendingOrderModalParams: {},
    isShowTwoFAMethodsModal: false,
    isShowPositionsMenuModal: false,
    isShowOrdersMenuModal: false,
    isShowAssetsModal: false,
    isShowFavoritesModal: false,
    isShowTradesModal: false,
    isTabsMenuClick: false,
    walletModalElement: '',
    isShowOpenOrderModal: false,
    openOrderModalParams: {},
    historyDetailId: null,
    editOrderNumber: '',
})();

export function getTradeMode() {
    if (store) {
        return store.getState().app.tradeMode;
    }
    const { defaultTradeMode, allowedTradeModes } = AppConfig;
    if (AppConfig.features.allowChangeTradeMode) {
        const tradeMode = Register.get(STORAGE_TRADE_MODE) || defaultTradeMode;
        return (allowedTradeModes.includes(tradeMode)) ? tradeMode : allowedTradeModes[0];
    }

    return defaultTradeMode;
}

export default function app(state = initialState, action) {
    const monitoredActions = handledActions();
    if (monitoredActions.includes(action.type)) {
        store.dispatch(trackingActions.doSendAppActionState(action, state));
    }

    switch (action.type) {

        case actionTypes.CHANGE_COLOR_THEME: {
            return state.merge({
                colorTheme: action.payload
            });
        }

        case actionTypes.GOT_MARGIN_CALL: {
            const showMarginCallModals = state.showMarginCallModals.push(action.payload);
            const isShowMarginCallModal = Boolean(!state.isShowDepositModal);
            return state.merge({
                isShowMarginCallModal,
            }).update('showMarginCallModals', stack => showMarginCallModals);
        }

        case actionTypes.CLOSE_MARGIN_CALL_MODAL: {
            const showMarginCallModals = state.showMarginCallModals.pop();
            const isShowMarginCallModal = Boolean(showMarginCallModals.size > 0 && !state.isShowDepositModal);
            const isShowStopOutModal = Boolean(!isShowMarginCallModal && state.showStopOutModals.size > 0 && !state.isShowDepositModal);
            return state.merge({
                isShowMarginCallModal,
                isShowStopOutModal,
            }).update('showMarginCallModals', stack => showMarginCallModals);
        }

        case actionTypes.GOT_STOP_OUT: {
            const { actionId, accountNumber, realizedPnL } = action.payload;

            const appStore =  store.getState();
            const { positions } = appStore.trade;
            const positionsCount = positions.list.filter(p => p.AccountNumber === accountNumber).size;

            const showStopOutModals = state.showStopOutModals.push({
                actionId,
                accountNumber,
                connectedPositionsCount: positionsCount,
                realizedPnL,
            });
            return state.update('showStopOutModals', stack => showStopOutModals);
        }

        case actionTypes.SHOW_STOP_OUT_MODAL: {
            return state.merge({
                isShowStopOutModal: true,
            });
        }

        case actionTypes.CLOSE_STOP_OUT_MODAL: {
            const { actionId } = action.payload;
            const showStopOutModals = state.showStopOutModals.filter(m => m.actionId !== actionId);
            const isShowMarginCallModal = Boolean(state.showMarginCallModals.size > 0 && !state.isShowDepositModal);
            const isShowStopOutModal = Boolean(!isShowMarginCallModal && showStopOutModals.size > 0 && !state.isShowDepositModal && !state.isShowMarginCallModal);
            return state.merge({
                isShowStopOutModal,
                isShowMarginCallModal,
            }).update('showStopOutModals', stack => showStopOutModals);
        }

        case actionTypes.DOWNLOADING:
        case actionTypes.DOWNLOADED:
        case actionTypes.READY:
            return state.merge({
                status: action.type,
            });
        case actionTypes.LOST_WS_CONNECTION:
            return state.merge({
                status: action.type,
            });

        case actionTypes.CHANGE_TRADE_MODE:

            if(state.animatedComponentsHiding){
                Register.set(STORAGE_TRADE_MODE, action.payload);

                setTimeout(() => store.dispatch(stopAnimatedComponentsAnimation()), ANIMATED_COMPONENTS_ANIMATION_SPEED);

                return state.merge({
                    animatedComponentsHiding: false,
                    tradeMode: action.payload
                });
            }

            setTimeout(() => store.dispatch(changeTradeMode(action.payload)), ANIMATED_COMPONENTS_ANIMATION_SPEED);

            return state.merge({
                animatedComponentsHiding: true,
                animatedComponentsChanging: true
            });

        case actionTypes.STOP_ANIMATED_COMPONENTS_ANIMATION:

            return state.merge({
                animatedComponentsChanging: false
            });

        case actionTypes.SHOW_TABS_MENU:
            return state.merge({
                isShowTabsMenu: action.payload
            });

        case actionTypes.ACTIVE_TAB:
            return state.merge({
                activeTab: action.payload
            });

        case actionTypes.ACTIVE_PANEL_MENU:
            return state.merge({
                isActivePanelMenu: action.payload
            });

        case actionTypes.ACTIVE_MOBILE_PANEL_MENU: {
            return state.merge({
                isActiveMobilePanelMenu: action.payload,
                isShowTwoFAModal: false
            });
        }

        case actionTypes.HEADER_TITLE:
            return state.merge({
                headerTitle: action.payload
            });

        case actionTypes.PAGE_NAME:
            return state.merge({
                page: action.payload
            });

        case actionTypes.PANEL_MENU_ACTIONS:
            return state.update('panelMenuActions', () => {
                return action.payload;
            });

        case actionTypes.RESET_IS_SUCCESSFUL_TRADE: {
            return state.merge({
                isSuccessfulTrade: false,
            });
        }

        case actionTypes.USER_NOTIFICATION_CLEAR:
            return state.update('userNotification', (model) => {
                return model.merge({
                    text: null,
                    updatedAt: Date.now(),
                });
            });

        case actionOrderTypes.ADD: {
            let isSuccessfulTrade = state.isSuccessfulTrade;
            const order = action.payload || {};

            for (let key in order) {
                const { OrderType, TradeMode } = order[key];

                if (OrderType === Types.ORDER_TYPE_LIMIT || OrderType === Types.ORDER_TYPE_STOP
                    || TradeMode === Types.INSTRUMENT_TRADE_MODE_EXCHANGE) {
                    isSuccessfulTrade = true;
                }
            }

            return state.update('userNotification', (model) => {
                return model.merge({
                    text: lang.t('Your request was accepted'),
                    updatedAt: Date.now(),
                });
            }).merge({
                isSuccessfulTrade,
            });
        }

        case actionOrderTypes.UPDATE: {
            const order = action.payload || {};
            const orderNumbers = Object.keys(order).filter(key => {
                const { OrderType, TradeMode } = order[key];

                return OrderType === Types.ORDER_TYPE_LIMIT 
                || OrderType === Types.ORDER_TYPE_STOP
                || TradeMode === Types.INSTRUMENT_TRADE_MODE_EXCHANGE
            });

            if (!orderNumbers.length) return state;

            return state.updateIn(['waitOrderNotifications', 'waitUpdate'], ids => {
                const hasSomething = ids.some((orderNumber) => orderNumbers.includes(orderNumber));
                return hasSomething ? new Map() : ids;
            });
        }

        case actionOrderTypes.REMOVE: {
            const order = action.payload || {};
            const orderNumbers = Object.keys(order).filter(key => {
                const { OrderType, TradeMode } = order[key];

                return OrderType === Types.ORDER_TYPE_LIMIT 
                    || OrderType === Types.ORDER_TYPE_STOP
                    || TradeMode === Types.INSTRUMENT_TRADE_MODE_EXCHANGE
            });

            if (!orderNumbers.length) return state;

            return state.updateIn(['waitOrderNotifications', 'waitRemove'], ids => {
                const hasSomething = ids.some((orderNumber) => orderNumbers.includes(orderNumber));
                return hasSomething ? new Map() : ids;
            });
        }

        case actionPositionTypes.ADD:
            return state.update('userNotification', (model) => {
                return model.merge({
                    text: lang.t('Your pending trade has been executed'),
                    updatedAt: Date.now(),
                });
            }).merge({
                isSuccessfulTrade: true,
            });

        case actionTypes.ADD_WAIT_ORDER_NOTIFICATION: {
            const { type, id } = action.payload;

            if (!state.waitOrderNotifications.has(type)) {
                return state;
            } 

            return state.updateIn(['waitOrderNotifications', type], ids => {
                if (typeof id === "object" && id.group) {
                    return ids.set("group", id);
                }
                return ids.set(id, id);

            });
        }

        case actionTypes.REMOVE_WAIT_ORDER_NOTIFICATION: {
            const { type, id } = action.payload;

            if (!state.waitOrderNotifications.has(type)) {
                return state;
            } 

            return state.updateIn(['waitOrderNotifications', type], ids => ids.delete(id));
        }

        case actionTypes.CLEAN_WAIT_ORDER_NOTIFICATION: {
            return state.set('waitOrderNotifications', new waitOrderNotificationsRecord());
        }

        case actionTypes.SET_LANGUAGE:
            if (lang.name !== action.payload && trackingActions.isTrackMasterMode()) // Set lang manually from client
                action.promise = actionTypes.setLanguage(action.payload).promise;

            return state.merge({
                lang: action.payload,
            });

        case actionTypes.LANGUAGE_CHANGING: {
            return state.merge({
                isLanguageChanging: true
            });
        }

        case actionTypes.LANGUAGE_CHANGED: {
            return state.merge({
                isLanguageChanging: false
            });
        }

        case actionTypes.OPEN_SIDE_PANEL: {
            return state.merge({
                isSidePanelOpen: true,
                sidePanelElement: action.payload,
            })
        }

        case actionTypes.CLOSE_SIDE_PANEL: {
            return state.merge({
                isSidePanelOpen: false,
                sidePanelElement: '',
                sidePanelComponent: null,
            })
        }

        case actionTypes.SET_SIDE_PANEL_COMPONENT: {
            return state.update('sidePanelComponent', () => {
                return action.payload;
            });
        }

        case actionTypes.SHOW_SETTINGS_GENERAL:
            return state.merge({
                isShowGlobalSettings: !!action.payload.isShow,
                globalSettingsGroup: action.payload.group,
            });

        case actionTypes.SHOW_CALL_BACK_FORM:
            return state.merge({
                isShowCallBackForm: action.payload
            });

        case actionTypes.SHOW_INSTRUMENT_DETAILS:
            return state.merge({
                isShowInstrumentDetails: action.payload.isShowInstrumentDetails,
            });

        case actionTypes.SHOW_INSTRUMENT_LIST:
            return state.merge({
                isShowInstrumentList: action.payload.isShowInstrumentDetails,
                instrumentListLeftPosition: action.payload.instrumentListLeftPosition,
                instrumentListRightPosition: action.payload.instrumentListRightPosition,
                mobileCurrentPanelTab: action.payload.isShowInstrumentDetails ? '/instruments' : '/trade',
            });

        case actionTypes.SET_FULLSCREEN_DIV_ID:
            return state.merge({
                fullScreenDivId: action.payload,
            });

        case actionTypes.SHOW_ACTIVITY_LOG_MODAL:
            return state.merge({
                isShowActivityLogModal: action.payload
            });

        case actionTypes.SHOW_HISTORY_MODAL:
            return state.merge({
                isShowHistoryModal: action.payload
            });

        case actionTypes.SHOW_CONSOLIDATED_REPORT_MODAL:
            return state.merge({
                isShowConsolidatedReportModal: action.payload
            });

        case actionTypes.SHOW_POSITION_EDIT_MODAL:
            return state.merge({
                editPositionNumber: action.payload
            });

        case actionTypes.SHOW_PARTIAL_CLOSE_MODAL:
            return state.merge({
                partialClosePositionNumber: action.payload
            });

        case actionTypes.SHOW_MARGIN_HISTORY_MODAL:
            return state.merge({
                isShowMarginHistoryModal: action.payload
            });

        case actionTypes.SHOW_ACCOUNT_REGISTER_MODAL:
            return state.merge({
                isShowAccountRegisterModal: action.payload
            });

        case actionTypes.SHOW_ON_BOARDING_MODAL:
            return state.merge({
                isShowOnBoardingModal: action.payload
            });

        case actionTypes.SHOW_APPROPRIATENESS_TEST_MODAL:
            return state.merge({
                isShowAppropriatenessTestModal: action.payload
            });

        case actionTypes.SHOW_WITHDRAW_MODAL:
            return state.merge({
                isShowWithdrawModal: action.payload,
                walletModalElement: action.payload ? Types.WALLET_WINDOW.WITHDRAW : '',
            });

        case actionTypes.SHOW_CHANGE_PASSWORD_MODAL:
            return state.merge({
                isShowChangePasswordModal: action.payload
            });

        case actionTypes.SHOW_INTERCHANGE_MODAL:
            return state.merge({
                isShowInterchangeModal: action.payload
            });

        case actionTypes.SHOW_TWOFA_MODAL:
            return state.merge({
                isShowTwoFAModal: action.payload
            });

        case actionTypes.SHOW_TWOFA_METHODS_MODAL:
            return state.merge({
                isShowTwoFAMethodsModal: action.payload
            });
        
        case actionTypes.SHOW_POSITIONS_MENU_MODAL:
            return state.merge({
                isShowPositionsMenuModal: action.payload
            });

        case actionTypes.SHOW_ORDERS_MENU_MODAL:
            return state.merge({
                isShowOrdersMenuModal: action.payload
            });

        case actionTypes.SHOW_ASSETS_MODAL:
            return state.merge({
                isShowAssetsModal: action.payload
            });
        
        case actionTypes.SHOW_FAVORITES_MODAL:
            return state.merge({
                isShowFavoritesModal: action.payload
            });
        
        case actionTypes.SHOW_TRADES_MODAL:
            return state.merge({
                isShowTradesModal: action.payload
            });

        case actionTypes.SHOW_SECURITY_MODAL:
            return state.merge({
                isShowSecurityModal: !!action.payload.isShow,
                securityModalGroup: action.payload.group,
                isTabsMenuClick: false,
            });

        case actionTypes.SHOW_EMAIL_TWOFA_MODAL:
            return state.merge({
                isShowEmailTwoFAModal: action.payload
            });

        case actionTypes.SHOW_CREATE_ACCOUNT_MODAL:
            return state.merge({
                isShowCreateAccountModal: action.payload,
                walletModalElement: action.payload ? Types.WALLET_WINDOW.CREATE_ACCOUNT : '',
            });

        case actionTypes.SHOW_DEPOSIT_MODAL: {
            const actionIsClose = !action.payload;
            if (actionIsClose) {
                const haveMarginMessageInSteck = state.showMarginCallModals.size > 0;
                const haveStopOutMessageInSteck = state.showStopOutModals.size > 0;
                if (haveMarginMessageInSteck) {
                    return state.merge({
                        isShowDepositModal: action.payload,
                        isShowMarginCallModal: true,
                    });
                } else if (haveStopOutMessageInSteck) {
                    return state.merge({
                        isShowDepositModal: action.payload,
                        isShowStopOutModal: true,
                    });
                } else {
                    return state.merge({
                        isShowDepositModal: action.payload,
                    });
                }
            } else {
                return state.merge({
                    isShowDepositModal: action.payload,
                    walletModalElement: action.payload ? Types.WALLET_WINDOW.DEPOSIT : '',
                });
            }
        }

        case actionTypes.SHOW_DEPOSIT_BXC_MODAL:
            return state.merge({
                isShowDepositBXCModal: action.payload
            });

        case actionTypes.SHOW_DEPOSIT_DEMO_ACCOUNT:
            return state.merge({
                isShowDepositDemoAccount: action.payload,
                walletModalElement: action.payload ? Types.WALLET_WINDOW.DEPOSIT_DEMO_ACCOUNT : '',
            });

        case actionTypes.SHOW_RISK_FREE_MODAL:
            return state.merge({
                isShowDepositModal: action.payload
            });

        case actionTypes.SHOW_TRANSACTION_HISTORY_MODAL:
            return state.merge({
                isShowTransactionHistoryModal: action.payload
            });

        case actionTypes.SHOW_EXCHANGE_HISTORY_MODAL:
            return state.merge({
                isShowExchangeHistoryModal: action.payload
            });

        case actionTypes.SHOW_WALLETS_MODAL:
            return state.merge({
                isShowWalletsModal: action.payload,
                walletModalElement: action.payload ? Types.WALLET_WINDOW.WALLET : '',
                isTabsMenuClick: false,
            });

        case actionTypes.SHOW_PROFILE_MODAL:
            return state.merge({
                isShowProfileModal: action.payload
            });

        case actionTypes.SHOW_PLATFORM_NOTIFICATIONS_MODAL:
            return state.merge({
                isShowPlatformNotificationsModal: action.payload
            });

        case actionTypes.SHOW_SUMSUB_KYC_MODAL: {
            return state.merge({
                isShowSumsubKycModal: action.payload
            });
        }

        case actionTypes.SHOW_USER_MENU: {
            return state.merge({
                isShowUserMenu: action.payload
            });
        }

        case actionTypes.SET_INVESTING_TAB:
            return state.merge({
                activeInvestingTab: action.payload
            });

        case actionTypes.SET_INVESTING_ID:
            return state.merge({
                activeInvestingId: action.payload
            });

        case actionTypes.SHOW_KYC_FINISH_NOTIFICATION:
            return state.merge({
                isShowKycFinishNotification: action.payload
            });

        case actionTypes.SHOW_CHART_SETTINGS_DIALOG:
            return state.merge({
                isShowChartSettingsDialog: action.payload
            });

        case actionTypes.SHOW_NOTIFICATION_MESSAGE:
            return state.update('notificationMessage', (model) => {
                return model.merge({
                    message: action.payload.message,
                    isNotificationMessageShow: true,
                    type: action.payload.type ? action.payload.type : Message.TYPE_INFORMATION,
                    callback: action.payload.callback ? action.payload.callback : null,
                    description: action.payload.description ? action.payload.description : null
                });
            });

        case actionTypes.CLEAR_NOTIFICATION_MESSAGE:
            return state.update('notificationMessage', (model) => {
                return model.merge({
                    message: null,
                    isNotificationMessageShow: false,
                    type: Message.TYPE_INFORMATION,
                    callback: null,
                    description: null
                });
            });

        case actionTypes.SHOW_CONFIRMATION_MESSAGE:
            const confirmId = action.payload.confirmId ? action.payload.confirmId : (~~(Math.random() * 1e8)).toString(16);
            const savedConfirms = Register.get('confirmations', []);

            if (savedConfirms.length && savedConfirms.indexOf(confirmId) > -1)
                return state;

            const newItem =  Map({
                [confirmId]: {
                    confirmId,
                    title: action.payload.title,
                    text: action.payload.text,
                    type: action.payload.type,
                    onButtonClick: action.payload.onButtonClick
                }
            });

            let newConfirmationList = Map(state.confirmationMessages).merge(newItem);

            if (newConfirmationList.size > 3)
                newConfirmationList = newConfirmationList.delete(newConfirmationList.first().confirmId);

            return state.merge({
                confirmationMessages: newConfirmationList
            });

        case actionTypes.HIDE_CONFIRMATION_MESSAGE:
            const newConfirmState = Map(state.confirmationMessages).delete(action.payload.confirmId);
            if (action.payload.isDontShow) {
                const savedConfirms = Register.get('confirmations', []);
                const resultConfirms = [...savedConfirms];

                if (resultConfirms.indexOf(action.payload.confirmId) < 0)
                    resultConfirms.push(action.payload.confirmId);

                Register.set('confirmations', resultConfirms);
            }

            return state.merge({
                confirmationMessages: newConfirmState
            });

        case actionTypes.START_MT:
            return state.merge({
                isShowMtMessage: true,
                mtBeginTime: action.payload.beginTime,
                mtEndTime: action.payload.endTime,
            });

        case actionTypes.HIDE_MT_MESSAGE:
            return state.merge({
                isShowMtMessage: false,
            });

        case actionTypes.REDIRECT_TO:
            return state.merge({
                redirectUrl: action.payload,
            });

        case trackingActions.SET_TRACKING_STATE:
            return state.merge({
                trackingState: action.payload,
            });

        case trackingActions.COMPONENT_GET_STATE:
            if (action.data) action.data['App'] = state;
            return state;

        case trackingActions.COMPONENT_UPDATE_STATE:
            if (action.payload.Name === 'App' && action.payload.State) {
                const newState = action.payload.State;
                newState.trackingState = state.trackingState;
                if (lang.name !== newState.lang) // Set lang manually from client
                    action.promise = actionTypes.setLanguage(newState.lang).promise;

                return state.merge(newState);
            }
            return state;

        case actionUserInfoTypes.UPDATE: {
            const { Verified2FAGroup, TwoFAStatus } = action.payload;
            
            if (Verified2FAGroup === 1) {
                return state.merge({
                    forceInitTwoFA: !TwoFAStatus,
                    isShowTwoFAModal: state.isShowTwoFAModal || !TwoFAStatus
                });
            }
            return state;
        }

        case actionUserInfoTypes.UPDATED: {
            const verifiedKYCGroup = Boolean(store.getState().user.info.model.VerifiedKYCGroup);

            if (!verifiedKYCGroup) {
                _.assignIn(AppConfig.features, AppConfig.offByVerifiedKYCGroup.features); // NOTE AppConfig isn't part of app redusers and working by own flow so bee carefull
            }

            return state;
        }

        case actionTypes.SET_INIT_STATE:
            return initialState.merge({
                status: actionTypes.READY,
                colorTheme: state.colorTheme
            });

        case actionTypes.CHART_CONTROLS_ELEMENT_IS_HOVER:
            return state.merge({
                isChartControlElementHover: action.payload
            });

        case actionTypes.SHOW_ACCOUNT_BLOCK_MESSAGE:
            return state.merge({
                isShowAccountBlockMessage: action.payload
            });

        case actionTypes.SHOW_TRADE_ON_DEMO_MODAL:
            return state.merge({
                isShowTradeOnDemoModal: action.payload
            });

        case actionTypes.SHOW_CLIENT_WRONG_TIME_MESSAGE:
            return state.merge({
                isClientWrongTimeShown: true,
            });

        case actionTypes.DISABLE_PASSWORD_CHANGE_MODAL_CLOSE:
            return state.merge({
                isDisabledPasswordChangeModalClose: action.payload,
            });

        case authTypes.LOG_IN_SUCCESS: {
            const { ServerTime } = action.payload;
            const normalizedServerDate = ServerTime ? ServerTime * 1000 : Date.now();
            const lastServerTime = Date.now();

            return state.merge({
                ServerTime: normalizedServerDate,
                lastServerTime,
                serverTimeOffset: Math.round((normalizedServerDate - lastServerTime) / 1000),
            });
        }

        case actionTypes.SHOW_SCREEN_SHARING_MESSAGE_REQUEST:
            return state.merge({
                isShowScreenSharingRequestMessage: action.payload,
            });

        case actionTypes.SHOW_SCREEN_SHARING_MESSAGE_END:
            return state.merge({
                isShowScreenSharingEndMessage: action.payload,
            });
        
        case actionTypes.SHOW_INSUFFICIENT_MESSAGE:
            return state.merge({
                isShowInsufficientFundsMessage: action.payload,
            });

        case actionTypes.SHOW_AUTO_LOGOUT_MESSAGE:
            return state.merge({
                isShowAutoLogOutMessage: action.payload,
            });

        case actionTypes.SET_RUNNING_AUTO_LOGOUT_TIMER:
            return state.merge({
                isRunningTimeOutTimer: action.payload,
            });

        case actionTypes.SET_WAITING_RESULT:
            return state.merge({
                isWaitingResult: action.payload
            });
            
        case actionTypes.SET_MOBILE_ROUTE:
            return state.merge({
                mobileCurrentPanelTab: action.payload,
            });

        case actionTypes.SHOW_ACCOUNT_MENU_MODAL:
            return state.merge({
                isShowAccountMenuModal: action.payload
            });

        case actionTypes.SHOW_SETTINGS_MENU_MODAL:
            return state.merge({
                isShowSettingsMenuModal: action.payload
            });
        case actionTypes.SHOW_INDICATORS_MODAL:
            return state.merge({
                isShowIndicatorsModal: action.payload
                });
        case actionTypes.SHOW_DRAW_OBJECTS_MODAL:
            return state.merge({
                isShowDrawObjectsModal: action.payload
            });

        case actionTypes.SHOW_ACCESS_KEYS_MODAL:
            return state.merge({
                isShowAccessKeysModal: action.payload,
                isTabsMenuClick: false,
            });

        case actionTypes.SHOW_NEWS_LIST_MODAL:
            return state.merge({
                isShowNewsListModal: action.payload
            });

        case actionTypes.SHOW_INVESTING_MODAL:
            return state.merge({
                isShowInvestingModal: action.payload
            });

        case actionTypes.BLOCK_OUTSIDE_CLICK_CLOSE:
            return state.merge({
                blockOutsideClickClose: action.payload
            });
            
        case actionTypes.BLOCK_NAVIGATION_MENU:
            return state.merge({
                blockNavigationMenu: action.payload
            });

        case messageBoxActions.SHOW_WINDOW:
            return state.merge({
                blockOutsideClickClose: true
            });

        case actionTypes.ADD_ADVANCED_SEARCH:
            const { category, request } = action.payload;
            const { advancedSearchRequests } = state;

            const requestsList = advancedSearchRequests.has(category) ? advancedSearchRequests.get(category) : [];
            const requestsListUpdated = [...new Set([request, ...requestsList])];
            const searchRequestsUpdated = advancedSearchRequests.set(category, requestsListUpdated);

            if (AppConfig.features.saveSearchRequests) {
                Register.set('search_requests',searchRequestsUpdated)
            }

            return state.merge({
                advancedSearchRequests: searchRequestsUpdated
            });

        case actionTypes.LOAD_SEARCH_REQUESTS:
            const searchRequests = new Map(Object.entries(Register.get('search_requests', [])))

            return state.merge({
                advancedSearchRequests: searchRequests
            });

        case actionTypes.SHOW_ECONOMIC_CALENDAR_MODAL:
            return state.merge({
                isShowEconomicCalendarModal: action.payload
            })

        case actionTypes.SHOW_PENDING_ORDER_MODAL:
            return state.merge({
                isShowPendingOrderModal: action.payload.show,
                pendingOrderModalParams: action.payload.params
            });
        
        case actionTypes.SHOW_OPEN_ORDER_MODAL:
            return state.merge({
                isShowOpenOrderModal: action.payload.show,
                openOrderModalParams: action.payload.params
            });
        
        case actionTypes.SHOW_EDIT_ORDER_MODAL:
            return state.merge({
                editOrderNumber: action.payload
            });

        case actionTypes.TABS_MENU_CLICK: {
            return state.merge({
                isTabsMenuClick: action.payload
            });
        }

        case actionTypes.SET_WALLET_MODAL_ELEMENT:
            return state.merge({
                walletModalElement: action.payload
            });

        case actionTypes.SHOW_REAL_WALLET_NOT_EXIST_MODAL:
            return state.merge({
                walletModalElement: action.payload
            });

        case actionTypes.SET_HISTORY_DETAIL_ID:
            return state.merge({
                historyDetailId: action.payload
            });

        default:
            return state;
    }
}
