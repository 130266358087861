/**
 * Created by ebondarev
 */
import validator from 'validator';

import { store } from '../store';
import AppConfig from '../AppConfig';
import date from './date';

export default class Validator {
    /**
     * @param month {string|number}
     * @returns {boolean}
     */
    static actualMonth(month) {
        let m = +month;

        return m >= 1 && m <= 12;
    }

    /**
     * @param fullYear {string|number}
     * @returns {boolean}
     */
    static actualYear(fullYear) {
        let date = new Date();

        return +fullYear >= date.getFullYear();
    }

    /**
     * Validate card number with Lugn algorithm
     * @param number {string}
     * @returns {boolean}
     */
    static cardNumber(number) {
        if (number.length < 13 || number.length > 19) {
            return false;
        }

        let sum = 0;

        for (let i = 0; i < number.length; i++) {
            let cardNum = parseInt(number[i]);

            if ((number.length - i) % 2 === 0) {
                cardNum = cardNum * 2;

                if (cardNum > 9) {
                    cardNum -= 9;
                }
            }

            sum += cardNum;
        }

        return sum % 10 === 0;
    }

    static expireDate(expireDate) {
        const year = date.getFullYear(expireDate.year);

        if (!Validator.actualYear(year) || !Validator.actualMonth(expireDate.month)) {
            return false;
        }

        const now = new Date();
        let expire = new Date();
        expire.setMonth(+expireDate.month - 1);
        expire.setYear(year);

        return now < expire;
    }

    /**
     * @param number {string}
     * @returns {boolean}
     */
    static cvv(number) {
        return number.length === 3;
    }

    /**
     * Validate IBAN value
     * @param iban {string}
     * @returns {boolean}
     */
    static iban(iban) {
        return iban.length >=13 && iban.length <= 19;
    }

    /**
     * @param number {string}
     * @returns {boolean}
     */
    static phoneNumber(number) {
        return (number && number.length > 7);
    }

    /**
     * @param number {string}
     * @returns {boolean}
     */
    static validateLength(number) {
        return (number && number.length >= 3 && number.length <= 32);
    }


    /**
     * @param value
     * @returns {boolean}
     */
    static isEmpty(value) {
        if (typeof value === 'undefined' || value === null) {
            return true;
        }

        return value.toString().length === 0;
    }


    static isEtheriumAddress(value) {
        if (/[^0-9a-z]/gi.test(value.toString())) {
            return false;
        }

        return true;
    }

    static isTransferAddress(value) {
        if (/[^0-9a-z_/-]/gi.test(value.toString())) {
            return false;
        }

        return true;
    }

    static isValidLogin = (login, { patterns = [], exceptions = [] }) => {
        if (!validator.isEmail(login, { allow_utf8_local_part: false })) {
            return false;
        }

        if (!Validator.isMatch(login, patterns, exceptions)) {
            return false;
        }

        return true;
    }

    static isValidPass = (password, patterns = [], minLength = 0, maxLength, exceptions = []) => {
        if (!Validator.isMatch(password, patterns, exceptions)) {
            return false;
        }

        if (password.length < minLength || password.length > maxLength) {
            return false;
        }

        return true;
    };

    static isValidName(value) {
        if(!/^[0-9a-zA-Z~`' \-.]+$/.test(value)){
            return false;
        }
        return true
    }

    static isMatch = (value, patterns = [], exceptions = []) => {
        let result = true;

        patterns.forEach(pattern => {
            if (!pattern.test(value)) {
                result = false;
            }
        });

        exceptions.forEach(pattern => {
           if (pattern.test(value)) {
               result = false;
           }
        });

        return result
    }

    static isKYCVerified() {
        const userInfo = store.getState().user.info.model;

        if (!Array.isArray(userInfo.Files)) return false;

        if (AppConfig.features.isEnableTierVerification) {
            if (AppConfig.features.isKYCVerifiedPriority) {
                if (userInfo.KYCVerified >= 1)
                    return true;
                else
                    return false;
            }

            let isFile1 = false, isFile2 = false, isFile3 = false, isFile4 = false;

            for (let i = 0; i < userInfo.Files.length; i++) {
                if (userInfo.Files[i].FileStatus !== 1) {
                    continue;
                }

                switch (userInfo.Files[i].FileType) {
                    case 1: isFile1 = true; break;
                    case 2: isFile2 = true; break;
                    case 4: isFile3 = true; break;
                    default: break;
                }

                if (userInfo.Files[i].FileType >= 10) {
                    isFile4 = true;
                }
            }

            const riskStatus = userInfo.RiskStatus ? userInfo.RiskStatus.replace(/[^0-9]/g, '') : "";

            if (userInfo.KYCVerified < 1 || (!isFile1 || !isFile2 || !isFile3) || (+riskStatus !== 1 && !isFile4)) {
                return false;
            }
        }

        return true;
    }

    static amountKeyPressValidator(event) {
        if (!/^[,.0-9]+$/.test(event.key)) {
            event.preventDefault();
        }
    }

    static allFieldsKeyPressValidator = e => {
        if (!/^[ ',./_+()"*№#@a-zA-Z0-9-]+$/.test(e.key)) {
            e.preventDefault();
        }
    }

    static decimalKeyPressValidator = e => {
        if (!/^[0-9]+$/.test(e.key)) {
            e.preventDefault();
        }
    }

    static numeralValidator = value => {
        return /^[0-9]+$/.test(value);
    }
}