import { store } from '../store';
import math from '../utils/math';
import { filterListByAccount } from '../reducers/trade/posittions';
import Types from '../classes/types';

export const getPositionsGroupSuccessMessageText = ({ side, netting }) => {
    if (!side && netting) return "All trades were successfully netted"
    if (side === "Buy" && netting) return "All BUY trades were successfully netted"
    if (side === "Sell" && netting) return "All SELL trades were successfully netted"
    if (!side && !netting) return "All trades were successfully closed"
    if (side === "Buy" && !netting) return "All BUY trades were successfully closed"
    if (side === "Sell" && !netting) return "All SELL trades were successfully closed"
}

export const getCrossRate = (from, to) => {
    return store.getState().trade.tickers.getCrossRate(from, to);
}

export const convertMoney = (amount, from, to) => {
    return amount * getCrossRate(from, to);
}

export const getTickerSizePrecision = ticker => {
    return math.countPrecision(+(ticker.LotSize * ticker.SizeMinimalChange).toFixed(12));
}

export const getDefaultOrderSize = (account, settings, ticker, isBuy = true) => {
    if (!account || !ticker || !settings) {
        return 0;
    }
    const { BaseCurrency, MTFreeMargin } = account;
    const { tradeSizeInPercents } = settings;
    const { Exp1, Exp2, tradeLeverage, Ask, MinTickerSize, sizeStep } = ticker;
    const tradeSize = Math.max(settings.tradeSize, MinTickerSize);
    const crossRate = getCrossRate(isBuy ? Exp2 : Exp1, BaseCurrency);
    const price = isBuy ? Ask : 1;
    const maxTickerSize = MTFreeMargin / (crossRate * tradeLeverage * price);

    const sizePrecision = getTickerSizePrecision(ticker);

    return tradeSizeInPercents ?
        math.floorToPrecision(math.getPercentValue(maxTickerSize, tradeSizeInPercents, sizeStep), sizePrecision) :
        tradeSize * ticker.LotSize;
}

export const calcAccountPnl = (crossRate, position) => {
    const { HoldPnLMaxValue, HoldPnLMinValue, MarkUpPnL, profit } = position;

    const realPnL = crossRate * profit + MarkUpPnL;

    if (!HoldPnLMinValue && !HoldPnLMaxValue) {
        return realPnL;
    }

    if (realPnL === 0) {
        const closestToZero = Math.abs(HoldPnLMinValue) < Math.abs(HoldPnLMaxValue) ? HoldPnLMinValue : HoldPnLMaxValue;
        return closestToZero;
    }

    if (HoldPnLMinValue === HoldPnLMaxValue) {
        return HoldPnLMinValue
    }
    
    const mid = (HoldPnLMaxValue - HoldPnLMinValue) / 2;
    let pnl = (HoldPnLMaxValue + HoldPnLMinValue) / 2 + realPnL;

    while (pnl > HoldPnLMaxValue || pnl < HoldPnLMinValue) {
        const modifier = pnl > HoldPnLMaxValue ? -1 : 1;
        pnl += mid * modifier;
    };

    return pnl;
}

export const calculateAccountMTMargin = account => {
    if (!account) {
        return 0;
    }
    
    const { tickers, positions, orders } = store.getState().trade;
    const { MarginModel } = account;

    const positionsList = filterListByAccount(positions.list, account.AccountNumber);
    const ordersList = orders.list.filter((order) => {
        return (account.AccountNumber === order.AccountNumber)
            && order.OrderType !== Types.ORDER_TYPE_LIMIT_TP
            && order.OrderType !== Types.ORDER_TYPE_STOP_SL});

    let positionsMargin = 0, ordersMargin = 0;

    if(positionsList && positionsList.size) {
        positionsMargin = MarginModel === Types.ACCOUNT_MODEL_FIXED
        ? calculateSumInitMargin(positionsList)
        : positionsList.toArray().reduce((margin, position) => {
            const tickerLeverage = tickers.getLeverage(position.SymbolId);
            const leverage = tickerLeverage > account.InitLeverage ? tickerLeverage : account.InitLeverage;

            let sideExp = '';
            let amountExp = 0;

            if (position.BuySell === Types.SIDE_SELL) {
                sideExp = position.Exp1;
                amountExp = position.AmountExp1;
            } else {
                sideExp = position.Exp2;
                amountExp = position.AmountExp2;
            }

            let amount = amountExp;

            if (sideExp !== account.BaseCurrency) {
                amount = amount * tickers.getCrossRate(sideExp, account.BaseCurrency);
            }

            return margin + Math.abs(amount * leverage);
        }, 0);
    }

    if (ordersList && ordersList.size) {
        ordersMargin = ordersList.toArray().reduce((margin, order) => {
            const ticker = tickers.list.get(order.SymbolId);
            const tickerLeverage = tickers.getLeverage(order.SymbolId);
            const leverage = order.TradeMode === 2 ? 1 :
                tickerLeverage > account.InitLeverage ? tickerLeverage : account.InitLeverage;

            if (ticker) {
                let sideExp = '';
                let orderVolume = 0;

                if (order.BuySell === Types.SIDE_SELL) {
                    sideExp = ticker.Exp1;
                    orderVolume = order.UnitVolume;
                } else {
                    sideExp = ticker.Exp2;
                    orderVolume = order.UnitVolume * order.Price;
                }

                let amount = orderVolume;

                if (sideExp !== account.BaseCurrency) {
                    amount = amount * tickers.getCrossRate(sideExp, account.BaseCurrency);
                }

                return margin + (amount * leverage);
            }

            return null;
        },0);
    }

    return positionsMargin + ordersMargin;
}

export const calculateSumInitMargin = positionsList => {
    let sum = 0;

    if (positionsList && positionsList.size) {
        sum = positionsList.toArray().reduce((acc, position) => acc + position.InitMargin, 0);
    }

    return sum;
}